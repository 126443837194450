import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { ActiveBonusAppliedResponse } from "@/types/Bonus/Response/ActiveBonusAppliedResponse";
import { MemberGroupBonusResponse } from "@/types/Bonus/Response/MemberGroupBonusResponse";
import { PromotionBonusResponse } from "@/types/Bonus/Response/PromotionBonusResponse";

export interface BonusStateInterface {
	bonusAllList: BonusResponse[];
	bonusList: BonusResponse[];
	memberBonus: BonusResponse[];
	uniqueCode: UniqueCodeResponse[];
	bonusClaimed: BonusClaimedResponse[];
	activeBonusApplied: ActiveBonusAppliedResponse[];
	memberGroupBonusResponse: MemberGroupBonusResponse[];
	promotionBonus: PromotionBonusResponse[];
}

function state(): BonusStateInterface {
	return {
		bonusAllList: [],
		bonusList: [],
		memberBonus: [],
		uniqueCode: [],
		bonusClaimed: [],
		activeBonusApplied: [],
		memberGroupBonusResponse: [],
		promotionBonus: [],
	};
}

export default state;
