import { ActionTree } from "vuex";
import memberAccountService from "@/network/MemberAccountService/MemberAccount";
import actionAuditService from "@/network/ActionAuditService/ActionAudit";
import vendorService from "@/network/VendorService/Vendor";
import { MemberAccountStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MemberAccountMutations } from "./mutations";
import { ResetWinoverRequest } from "@/types/MemberAccount/Request/ResetWinoverRequest";
import { ActionAuditContent } from "@/types/ActionAudit/Request/ActionAuditRequest";
import moment from "moment";

export const actions: ActionTree<MemberAccountStateInterface, RootState> = {
	async fetchVendorBalance(context, payload) {
		// const vendorAccountId = context.rootGetters["vendors/getVendorAccount"].id;
		// const vendorAccount = await vendorService.getVendorAccount(payload.vendorId);

		const loginId = context.rootGetters["identityServer/getLoginId"];
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getVendorBalance(
			payload.vendorAccountId,
			memberId,
			loginId,
			payload.isFromDB
		);
		context.commit(MemberAccountMutations.SET_VENDOR_BALANCE, res);
		return res;
	},
	async fetchMemberWinover(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getMemberWinover(memberId);
		context.commit(MemberAccountMutations.SET_MEMBER_WINOVER, res);
	},
	async fetchMemberTurnover(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getMemberTurnover(memberId);
		context.commit(MemberAccountMutations.SET_MEMBER_TURNOVER, res);
	},
	async fetchExternalVendorWinover(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getMemberWinover(memberId, payload);
		return res;
	},
	async resetWinover(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const winoverRequest: ResetWinoverRequest = {
			group: payload.group,
			winover: 0,
			lockdown: 0,
			memberId: memberId,
			clientId: process.env.VUE_APP_IdentityServer_ClientId,
		};
		const beforeWinoverRequest: ResetWinoverRequest = {
			group: payload.group,
			winover: payload.winover,
			lockdown: payload.lockdown,
			memberId: memberId,
			clientId: process.env.VUE_APP_IdentityServer_ClientId,
		};

		const actionContent: ActionAuditContent = {
			before: beforeWinoverRequest,
			after: winoverRequest,
		};
		await memberAccountService.resetWinover(winoverRequest);
		await actionAuditService.addActionAuditTrail(
			"MemberAccoutService",
			"Reset Winover",
			JSON.stringify(actionContent),
			memberId
		);
	},
	async fetchMemberAccount(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getMemberAccount(payload.vendorAccountId, memberId);
		context.commit(MemberAccountMutations.SET_MEMBER_ACCOUNT, res);
		return res;
	},
	async updateGamePassword(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.updateGamePassword(
			loginId,
			memberId,
			payload.vendorId,
			payload.vendorGameLoginId,
			payload.vendorGamePassword,
			payload.phone,
			payload.newPassword
		);
		context.commit(MemberAccountMutations.SET_MEMBER_ACCOUNT, res);
	},
	async fetchMemberWallet(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberAccountService.getMemberWallet(memberId);
		const currentTime = moment().format("DD MMM YYYY, HH:mm");
		context.commit(MemberAccountMutations.SET_LATEST_WALLET_TIME, currentTime);
		context.commit(MemberAccountMutations.SET_MEMBER_WALLET, res);
		return res;
	},
	async clearMemberData(context) {
		const currentTime = moment().format("DD MMM YYYY, HH:mm");
		context.commit(MemberAccountMutations.SET_LATEST_WALLET_TIME, currentTime);
		context.commit(MemberAccountMutations.RESET_MEMBER_ALL);
	},
};
