import terms from "@/resource/terms/terms.ms";
import privacyPolicy from "@/resource/privacyPolicy/privacyPolicy.ms";
import responsible from "@/resource/responsibleGaming/responsible.ms";
import goPlusTerms from "@/resource/goPlusTerms/goPlusTerms.ms";

const brandName = "Win'n Go";
const year = "2024";

export default {
	common: {
		brandName: `${brandName}`,
		year: `${year}`,
		pageNotFound: "Halaman Tidak Ditemui",
		userId: "ID Pengguna",
		dob: "Tarikh Lahir",
		games: "Permainan",
		close: "Tutup",
		doNotShowAgain: "Jangan tunjukkan lagi dalam hari ini",
		readAll: "Lihat Semua",
		announcement: "Pengumuman",
		live: "Live",
		download: "Muat Turun",
		username: "Nama Pengguna",
		fullName: "Name Penuh (mengikut Akaun Bank)",
		password: "Kata Laluan",
		confirmPassword: "Kata Laluan Pengesahan",
		currentPassword: "Kata Laluan Semasa",
		newPassword: "Kata Laluan Baharu",
		copySuccess: "Berjaya disalin",
		copyFailed: "Gagal menyalin",
		search: "Cari",
		numberOfResults: "{number} keputusan carian",
		itemsPerPage: "Keputusan setiap halaman",
		submit: "Serahkan",
		update: "Perbarui",
		confirm: "Confirm",
		select: "Pilih",
		pleaseLogin: "Sila log masuk untuk meneruskan.",
		yes: "Ya",
		no: "Tidak",
		requestTac: "Minta TAC",
		verificationCode: "Kod Pengesahan",
		resendInSeconds: "Serahkan semula dalam {time} saat",
		phoneNumber: "Nombor Telefon",
		EnterYourPhoneNumber: "Masukkan Nombor Telefon anda",
		EnterYourPassword: "Masukkan Kata Laluan anda",
		EnterYourFullName: "Masukkan Nama Penuh anda",
		RetypeYourPassword: "Taip semula Kata Laluan anda",
		referralCode: "Kod Rujukan",
		termsConsent: {
			iAmOver18: "Saya berumur lebih dari 18 tahun dan saya menerima",
			and: "dan",
			iHaveReadAndAgreedToThe: "Saya telah membaca dan bersetuju dengan",
		},
		nextStep: "Langkah seterusnya",
		next: "Seterusnya",
		accountSetup: "Persediaan Akaun",
		tac: "TAC",
		done: "Selesai",
		back: "Balik",
		resendTac: "Serahkan semula TAC",
		success: "Berjaya",
		registerSuccess: "Akaun anda telah berjaya dibuka!",
		on: "ON",
		off: "OFF",
		min: "Min",
		max: "Max",
		notice: "Notis Penting",
		in: "Masuk",
		out: "Keluar",
		startDate: "Tarikh Mula",
		endDate: "Tarikh Tamat",
		noData: "Tiada data",
		provider: "Pembekal",
		selectProvider: "Pilih pembekal",
		facebook: "Facebook",
		whatsApp: "WhatsApp",
		telegram: "Telegram",
		email: "Mel",
		line: "LINE",
		twitter: "Twitter",
		claim: "Tuntut",
		claimNow: "Tuntut Sekarang",
		areYouSure: "Adakah anda pasti?",
		cannotChangeAgain: "Anda tidak akan dapat berubah lagi selepas ini.",
		welcomeToWinnGo: `Selamat datang ke ${brandName}`,
		loginToContinue: `Log masuk untuk meneruskan pengalaman anda dengan aplikasi ${brandName}`,
		ResetPassword: "Menetapkan semula kata laluan",
		ResetPasswordDesc: "Sila taipkan nombor telefon anda untuk menetapkan semula kata laluan anda",
		EnterNewPassword: "Sila masukkan kata laluan baharu anda",
		more: "Lebih",
		selectDate: "Pilih Tarikh",
		selectCategory: "Pilih Kategori",
		today: "Hari ini",
		last24Hours: "24 jam lepas",
		lastNDays: "{days} hari lepas",
		lastUpdateOn: "Kemas kini pada {updateTime}",
		light: "Terang",
		dark: "Gelap",
		system: "Sistem",
		note: "Nota",
		bankInfoNote: "Hanya satu akaun bank boleh dipautkan ke akaun anda",
		scanToObtainReferralCode: "Sila imbas kod QR untuk mendaftar",
		registerWelcome:
			"Untuk menjadi ahli, hanya perlu imbas kod QR yang disediakan oleh ahli yang sedia ada. Setelah diimbas, ikuti arahan untuk melengkapkan pendaftaran anda. Selamat datang!",
		shareLinkText: `Sertai ${brandName} dengan Saya & Dapatkan Ganjaran Tunai!`,
		cancel: "Batal",
		checkIn: "Check-in",
		total: "Jumlah",
		viewReferrals: "Lihat Rujukan",
		viewReceipt: "Lihat Resit",
		viewRemarks: "Lihat Pernyataan",
		totalBalance: "Jumlah Baki",
		totalEarnings: "Jumlah Pendapatan",
		principalAmount: "Jumlah Prinsipal",
		earnedInterest: "Faedah Diperolehi",
		nettDailyReturnRate: "Kadar Pulangan Harian Bersih",
		nettWeeklyReturnRate: "Kadar Pulangan Mingguan Bersih",
		cashIn: "Amaun Masuk",
		cashOut: "Amaun Keluar",
		totalCashOut: "Jumlah Amaun Keluar",
		recentTransactions: "Transaksi Terkini",
		recentPayouts: "Pembayaran Terkini",
		details: "Butiran",
		viewAll: "Lihat semua",
		viewLess: "Kurangkan",
		goPlusWarning:
			"Baki anda menghampiri had maksimum: {max}. Apabila anda telah mencapai had, anda akan berhenti menerima pendapatan.",
		goPlusLimit: "Baki anda telah mencapai had maksimum: {max}. Sila tunaikan untuk terus menerima pendapatan.",
		pullDownToRefresh: "Tarik untuk muat semula",
		releaseToRefresh: "Lepaskan untuk muat semula",
		releaseToFullRefresh: "Lepaskan untuk muat semula penuh",
		onLoading: "Sedang dimuatkan...",
		copyright: `Hak Cipta Terpelihara © ${year} ${brandName}.`,
		termsAndConditions: "Terma dan Syarat",
		FAQ: "FAQs",
		day: "hari",
		week: "minggu",
		dailyPayout: "Pendapatan Harian",
		weeklyPayout: "Pendapatan Mingguan",
		onlyRecordsUpToNDays: "Hanya rekod sehingga {days} hari tersedia",
		cashInVia: "Amaun Dimasukkan Dari",
		cashOutTo: "Keluarkan Amaun ke",
		enterAmount: "Masukkan jumlah",
		enterAmountMin: "Masukkan jumlah (Min. {min})",
		maximum: "Maksimum",
		minimum: "Minimum",
		transferableBalance: "Baki boleh dipindah",
		totalAmount: "Jumlah amaun",
		allAmountWillBeCashedOut: `Semua jumlah dalam dompet ${brandName}+ anda akan dikeluarkan.`,
		question: "S",
		answer: "J",
		addToHomeScreen: "Tambahkan ke Skrin Utama",
		addToHomeScreenInfo: "Tambahkan ke skrin utama dan nikmati pengalaman mengasyikkan!",
		install: "Pasang",
		referralNow: "Rujuk Sekarang!",
		interestInsufficientBalance:
			"Anda memerlukan baki minimum yang boleh dipindahkan sebanyak {min} untuk membuat amaun masuk.",
		noBalanceToBeCashedOut: "Tiada baki untuk diamaun keluar.",
		comingSoon: "Akan Datang",
		underMaintenance: "Dalam Penyelenggaraan",
		confirmDeposit: "Sahkan Deposit",
		depositToLockedWallet:
			"Amaun deposit anda yang sebanyak {currency}{amount} akan dikreditkan ke dalam Dompet Terkunci [{category}].",
		yourNewTurnover: "Turnover baharu anda ialah {jumlah}.",
		doYouWishToProceed: "Adakah anda ingin meneruskan?",
		hot: "Hot",
		vip: "VIP",
		category: "Kategori",
		vendor: "Vendor",
		betHistory: "Pertaruhan Historyndor",
		ticketsAvailable: "Tiket tersedia",
		redirecting: "Menghala Semula",
		youAreBeingPaymentGateway: "Anda kini sedang dihalakan ke gerbang pembayaran...",
		unsupportedBrowser: "Pelayar tidak disokong",
		pleaseSwitchBrowser: `Untuk pengalaman ${brandName} yang optimum, sila tukar kepada penyemak imbas yang disokong`,
		noBonusApplied: "No bonus aktif",
		bonusInProgress: "Bonus dalam proses",
	},
	scan: {
		ScanBarCodeOrQRCode: "Imbas Kod Bar atau Kod QR",
		ScanFromGallery: "Imbas Dari Galeri",
	},
	games: {
		viewMoreGames: "Lihat Lagi Permainan",
		speedBacarrat: "Speed Bacarrat",
		betNow: "Pertaruhan Sekarang",
		playNow: "Main Sekarang",
		play: "Main",
		instruction: "Arahan",
		instructionDetails: "Sila gunakan Nama Pengguna dan Kata Laluan yang disediakan untuk log masuk.",
	},
	account: {
		mainWallet: "Dompet Utama",
		lockedWallet: "Dompet Terkunci",
		depositNow: "Deposit Sekarang",
		transferAmount: "Pindah Amaun",
		promoCode: "Kod Promosi",
		promotion: "Promosi",
		selectPromotion: "Pilih promosi",
		noPromotion: "Tiada promosi",
		bankDetails: "Butiran Bank",
		bankName: "Nama Bank",
		accountHolderName: "Nama Pemegang Akaun",
		accountName: "Nama Akaun",
		accountNumber: "Nombor akaun",
		selectBank: "Pilih bank",
		viewFullWallet: "Lihat Dompet",
		general: "Umum",
		payment: "Bayaran",
		banking: "Perbankan",
		account: "Akaun",
		autoTransfer: "Pemindahan Auto Dompet Utama",
		paymentMethod: "Cara Pembayaran",
		selectPaymentMethod: "Pilih cara pembayaran",
		depositAmount: "Jumlah Deposit",
		deposit: "Deposit",
		depositNotice: [
			{
				notice: "Sila periksa maklumat akaun bank terkini kami sebelum membuat deposit.",
			},
			{
				notice: "Nama akaun deposit mestilah sama dengan nama penuh yang didaftarkan.",
			},
			{
				notice: "Deposit yang menggunakan akaun pihak ketiga, cek, draf bank dan kad kredit tidak dibenarkan.",
			},
			{
				notice: "Permintaan deposit harus diajukan hanya setelah transaksi deposit berjaya.",
			},
		],
		withdrawalNotice: [
			{
				notice: "Nama bank pengeluaran mesti sama dengan nama penuh yang didaftarkan. Ahli tidak dibenarkan mengeluarkan dana ke akaun bank pihak ketiga.",
			},
			{
				notice: "Pastikan nama akaun bank & nombor akaun bank betul dan tepat sebelum menyerahkan pengeluaran.",
			},
			{
				notice: "Setelah berjaya menghantar borang pengeluaran anda dan bersihkan dana di akaun kami, pasukan kami akan memproses transaksi anda secepat mungkin. Sekiranya masa memproses lebih dari 10 minit, sila hubungi Sokongan Perkhidmatan Pelanggan kami. Mereka akan melayani anda 24/7 dan bila-bila masa.",
			},
		],
		depositChannel: "Saluran Deposit",
		selectChannel: "Pilih saluran",
		onlineTransfer: "Permindahan dalam talian",
		cashDepositMachine: "Mesin Deposit Tunai",
		atm: "ATM",
		overTheCounter: "Di Kaunter",
		cheque: "Periksa",
		uploadReceipt: "Muat Naik Resit",
		minWithdrawal: "Min Pengeluaran",
		maxWithdrawal: "Max Pengeluaran",
		dailyLimit: "Had Harian",
		withdrawalAmount: "Jumlah Pengeluaran",
		availableBalance: "Baki Tersedia",
		transactionHistory: "Transaksi",
		transferFrom: "Pemindahan Dari",
		transferTo: "Pemindahan Kepada",
		selectWallet: "Pilih dompet",
		mainWalletBalance: "Baki Dompet Utama",
		transferBackAll: "Pindah Balik Semua",
		winover: "Winover",
		turnover: "Turnover",
		turnoverBalance: "Baki Turnover",
		referNow: "Rujuk Sekarang",
		referralRewards: "Ganjaran Rujukan",
		youHave: "Anda mempunyai",
		connections: "perhubungan",
		totalCashEarned: "Jumlah tunai yang diperolehi",
		successfulReferrals: "Rujukan yang berjaya",
		yourReferrals: "Rujukan Anda",
		referralStates: {
			registered: "Berdaftar",
			depositSuccessful: "Deposit berjaya",
			claim: "Tuntutan",
			claimed: "Dituntut",
		},
		unclaimed: "Belum dituntut",
		inProgress: "Sedang berjalan",
		claimAll: "Tuntut Semua",
		allClaimed: "Semua dituntut",
		referralCode: "Kod rujukan",
		referralLink: "Pautan rujukan",
		successfulRegistrations: "Pendaftaran berjaya",
		shareToFriends: "Kongsikan kepada rakan-rakan",
		referralTerms: "Terma dan syarat dikenakan untuk semua bonus rujukan.",
		referralInstructions:
			"Tunjukkan kod QR ini kepada rakan, imbas dan lengkapkan pendaftaran untuk menerima bonus rujukan.",
		minDepositRequired:
			"Sekurang-kurangnya {minDeposit} deposit diperlukan untuk menyertai tawaran program rujukan.",
		birthdayBonus: "Bonus Hari Jadi",
		birthdayConditionYearlyClaim: "Ahli boleh menuntut bonus ini sekali setahun.",
		birthdayConditionFirstDeposit: "Bonus ini hanya terhad kepada ahli yang telah membuat deposit pertama.",
		birthdayConditionBirthdayMonth: "Ahli boleh menuntut bonus hari jadi 15 hari sebelum atau selepas hari jadi.",
		birthdayConditionMemberDuration: `Ahli dikehendaki menyertai ${brandName} sekurang-kurangnya satu bulan`,
		birthdayExample:
			"Contoh: Kalau hari jadi ahli ialah pada 16 Mei, dia boleh menuntutnya antara 1 Mei hingga 31 Mei. (Sebelum atau selepas 15 hari)",
		birthdayNotice1: "Sila pastikan anda telah mengisi hari jadi anda di halaman ",
		birthdayNotice2: ".",
		changingBirthdayTo: "Menukar hari jadi kepada",
		minAmount: "Jumlah min: {min}",
		WinNGoPlus: `${brandName}+`,
		EarnDaily: "Pulangan > {earn}% Setiap Hari",
		BonusApplied: "Bonus Aktif",
		ReferEarn: "Rujuk & Untung",
		GoPlusBalance: `Jumlah ${brandName}+`,
		LockBalance: "Baki Terkunci",
		AddPaymentInfo: "Tambahkan Info Pembayaran",
		NoApplyBonus: "Tiada Promosi",
		ApplyBonus: "Memohon Bonus",
		TargetTurnover: "Sasaran Turnover",
		GoApply: "Mohon Sekarang >>>",
	},
	checkIn: {
		DailyCheckIn: "Check In Harian",
		CheckInToday: "Check-in Hari Ini dan Dapatkan",
		CheckInSlogan: "Check-in setiap hari untuk mengumpul semua ganjaran!",
		RecheckInLimit: "Recheck-in Yang Tinggal",
		Day: "Hari ke-{day}",
		minigames: "Tiket Mini Game x {amount}",
		adjustment: "Kredit Percuma {currency} {amount}",
		addonbonus: "Bonus Tambahan {amount}%",
		bonus: "Bonus {currency} {amount} Turnover x {rollover}",
		empty: " - ",
		chips: "Chip x {amount}",
		backdateCheckIn: "Check-in Tarikh Lepas",
		ticket: "Tiket",
		recheckIn: "Recheck-in",
		recheckInRequirement: "Keperluan Recheck-in",
		checkIn: "Check-in",
		AddOnBonusTitle: "Tambah Bonus",
		MissedDays: "Check-in terlepas",
		days: "hari",
		Credit_1: "Sasaran Deposit Harian",
		Credit_2: "Sasaran Turnover Harian",
		bonusAppliedForAddonBonus: "Promosi yang layak untuk bonus tambahan",
		unlockMission: "Misi Buka Kunci",
		missionComplete: "Misi Selesai",
		checkInNotice: [
			{
				notice: `Acara ini tersedia untuk ahli ${brandName} sahaja`,
			},
			{
				notice: "Kredit percuma akan dikreditkan ke dompet anda serta-merta selepas check-in berjaya.",
			},
			{
				notice: "Jika hadiah check-in adalah bonus, amaun bonus akan dikreditkan ke dompet terkunci anda dengan keperluan turnover masing-masing.",
			},
			{
				notice: "Sebarang pertaruhan yang mengakibatkan void, tie, cancelled atau dibuat pada pihak yang bertentangan dengan keputusan yang sama tidak akan dikira sebagai turnover yang sah.",
			},
			{
				notice: `${brandName} ialah pengadil tunggal acara ini, dan keputusannya adalah muktamad.`,
			},
		],
		errorMsg: {
			E1001: "Recheck-in yang tersedia telah digunakan.",
			E1002: "Recheck-in tidak tersedia.",
			E1003: "Recheck-in hari ini telah selesai.",
			E1004: "Sasaran Misi masih belum tercapai.",
			E1005: "Turnover wujud.",
			E1006: "Sila hubungi perkhidmatan pelanggan kami.",
			E1007: "Recheck-in hari ini telah selesai.",
			E1008: "Recheck-in hari ini telah selesai.",
			E1009: "Sila hubungi perkhidmatan pelanggan kami.",
			E1010: "Sila hubungi perkhidmatan pelanggan kami.",
			E1011: "Sila hubungi perkhidmatan pelanggan kami.",
			E1012: "Sila hubungi perkhidmatan pelanggan kami.",
			E1013: "Sila hubungi perkhidmatan pelanggan kami.",
			E1014: "Sila hubungi perkhidmatan pelanggan kami.",
			E1015: "Tiket deposit sebelumnya masih dalam proses.",
			E9999: "Sila hubungi perkhidmatan pelanggan kami.",
		},
	},
	inbox: {
		deleteInbox: "Padam mesej",
	},
	bonusTypes: {
		SLOTS: "Bonus Slot",
		"LIVE CASINO": "Bonus Live Casino",
		FISHING: "Bonus Fishing",
		SPORTS: "Bonus Sports",
		COCKFIGHT: "Bonus Sabung Ayam",
		LOTTERY: "Bonus 4D",
		"FAST GAME": "Bonus Fast Game",
		"NEW MEMBER": "Ahli Baru",
		REFERRAL: "Bonus Rujukan",
		EVENT: "Bonus Acara",
		WELCOME: "Bonus Selamat datang",
		RESCUE: "Bonus Penyelamat",
		BIRTHDAY: "Bonus Hari Lahir",
		SPECIAL: "Bonus Istimewa",
	},
	category: {
		all: "Semua",
		sports: "Sports",
		casino: "Casino",
		livecasino: "Live",
		fishing: "Fishing",
		slots: "Slot",
		special: "Istimewa",
		autoDeposit: "Auto",
		manualDeposit: "Manual",
		eWallet: "eDompet",
		deposit: "Deposit",
		withdrawal: "Pengeluaran",
		transfer: "Pemindahan",
		betHistory: "Pertaruhan",
		bonus: "Bonus",
		adjustment: "Pelarasan",
		loginPassword: "Kata Laluan Akaun",
		gamePassword: "Kata Laluan Permainan",
		lottery: "4D",
		cockfight: "Sabung Ayam",
		minigames: "Mini Game",
		promotion: "Promosi",
		payment: "Bayaran",
		system: "Sistem",
		notification: "Notis",
		fastgame: "Fast Game",
		interest: `${brandName}+`,
		lock: "Terkunci",
	},
	footer: {
		followUs: "Ikut kami",
		paymentMethod: "Kaedah Pembayaran",
		poweredBy: "Dikuasai Oleh",
		copyright: `Hak Cipta Terpelihara © ${year} ${brandName} {'|'} 18+`,
	},
	route: {
		home: "Laman Utama",
		download: "Muat Turun",
		login: "Log Masuk",
		register: "Daftar",
		forgotpassword: "Lupa Kata Laluan",
		slots: "Slot",
		casino: "Live Casino",
		fishing: "Fishing",
		sports: "Sports",
		promotion: "Promosi",
		aboutus: "Tentang Kita",
		contact: "Hubungi Kami",
		terms: "Terma dan Syarat",
		privacypolicy: "Dasar Privasi",
		responsiblegaming: "Permainan Bertanggungjawab",
		vip: "VIP",
		language: "Bahasa",
		inbox: "Peti Masuk",
		interchange: "Pertukaran",
		profile: "Profil",
		bankinfo: "Maklumat Bank",
		referral: "Rujukan",
		bonus: "Bonus",
		deposit: "Deposit",
		transfer: "Pemindahan",
		withdrawal: "Pengeluaran",
		statement: "Penyata",
		changepassword: "Tukar Kata Laluan",
		quicktransfer: "Pemindahan Cepat",
		logout: "Log Keluar",
		qrcode: "Kod QR",
		scan: "Imbas",
		myaccount: "Seting",
		setting: "Profil",
		checkin: "Check-in",
		notfound: "Halaman Tidak Wujud",
		livechat: "Bantuan",
		theme: "Tema",
		interest: `${brandName}+`,
		cashin: "Amaun Masuk",
		cashout: "Amaun Keluar",
		fastgame: "Fast Game",
		notification: "Pemberitahuan",
		tutorial: "Tutorial",
	},
	tutorials: {
		withdraw: "Cara Pengeluaran",
		deposit: "Cara Deposit",
		register: "Cara Mendaftar",
	},
	language: {
		en: "English",
		ms: "Malay",
		zh: "中文",
	},
	validation: {
		required: "Medan ini diperlukan",
		minAmount: "Sila masukkan jumlah minimum {minAmount}",
		maxAmount: "Sila masukkan jumlah maksimum {maxAmount}",
		minLength: "Sila masukkan sekurang-kurangnya {minLength} aksara",
		maxLength: "Melebihi had aksara: {maxLength}",
		numeric: "Sila masukkan nombor sahaja",
		fullNameInvalid:
			"Format nama tidak betul (hanya huruf, ruang, dan '-' dibenarkan; tiada ruang berturut-turut, nama tidak boleh bermula atau berakhir dengan ruang)",
		passwordNotMatch: "Kata laluan tidak sepadan",
		pleaseAgree:
			"Sila nyatakan bahawa anda telah membaca dan bersetuju menerima Terma dan Syarat serta Dasar Privasi",
		cannotTransferToSameWallet: "Tidak boleh pindahkan ke dompet yang sama.",
		newPasswordMustBeDifferent: "Kata laluan baharu mestilah berbeza daripada kata laluan lama.",
	},
	toast: {
		fetchError: "Gagal mengambil data, sila hubungi khidmat pelanggan kami. {error}",
		autoLogout: "Auto log keluar dalam masa 5 saat.",
		sessionExpired: "Sesi tamat tempoh. Sila log masuk semula.",
		logoutSuccess: "Log keluar berjaya.",
		launchFailed: "Permainan pelancaran gagal, sila hubungi khidmat pelanggan kami. {error}",
		"turnover exist": "Turnover wujud, baki tidak akan dipindahkan.",
		"winover exist": "Winover wujud, baki tidak akan dipindahkan.",
		transferSuccess: "Pemindahan berjaya.",
		transferFailed: "Pemindahan gagal. {error}",
		tacSuccess: "Berjaya meminta TAC. Sila semak telefon anda.",
		tacFailed: "Permintaan TAC gagal. Sila cuba sebentar lagi.",
		tacVerificationFailed: "Pengesahan TAC gagal.",
		submitSuccess: "Berjaya diserahkan.",
		submitFailed: "Penyerahan gagal. Sila cuba sebentar lagi. {error}",
		loginFailed: "Nama Pengguna/Kata Laluan Tidak Sah",
		loginSuccess: "Log masuk berjaya.",
		registerSuccess: "Akaun berjaya didaftarkan.",
		resetPasswordSuccess:
			"Berjaya diserahkan. Sila semak telefon mudah alih anda untuk mendapatkan pautan seting semula kata laluan",
		autoTransferEnabled:
			"Anda telah menaktifkan Auto Transfer. Sila berikan sehingga 1 minit untuk sistem mengemas kini pilihan anda. Terima kasih.",
		autoTransferDisabled:
			"Anda telah menyahaktifkan Auto Transfer. Sila berikan sehingga 1 minit untuk sistem mengemas kini pilihan anda. Terima kasih.",
		changePasswordSuccess: "Kata laluan berjaya ditukar.",
		changePasswordFailed: "Penukaran kata laluan gagal. {error}",
		checkInSuccess: "Check-in berjaya",
		checkInFailed: "Check-in gagal. {error}",
		pleaseCheckInFirst: "Sila check-in dulu.",
		unlockMissionToCheckIn: "Buka kunci misi untuk mendaftar masuk.",
		BookmarkSuccess: "Ditanda buku",
		UnBookmarkSuccess: "Nyahtanda buku",
		MarkAsReadSuccess: "Ditanda Sebagai Dibaca",
		MarkAsUnreadSuccess: "Ditanda Sebagai Belum Dibaca",
		RemoveSuccess: "Padam Mesej",
		BookmarkFailed: "Kesilapan: Gagal Ditanda buku",
		UnBookmarkFailed: "Kesilapan: Gagal Nyahtanda buku",
		MarkAsReadFailed: "Kesilapan: Gagal Sebagai Dibaca",
		MarkAsUnreadFailed: "Kesilapan: Gagal Sebagai Sebelum Dibaca",
		RemoveFailed: "Kesilapan: Gagal Padam Mesej",
		ticketSuccess: "ID Transaksi : {id} telah diluluskan.",
		ticketReject: "ID Transaksi : {id} telah ditolakkan. Sila hubungi Perkhidmatan Pelanggan kami.",
		claimSuccessful: "Tuntutan berjaya.",
		claimUnsuccessful: "Tuntutan tidak berjaya. {error}",
		dailyMissionIncomplete_1: "Sila capai sasaran deposit anda untuk memperoleh peluang recheck-in",
		dailyMissionIncomplete_2: "Misi harian anda tidak lengkap, sila penuhi sasaran turnover anda",
		newInboxReceived: "Anda mempunyai mesej baharu!",
		checkInNotStartYet: "Maaf, acara check-in belum bermula. Sila semak semula kemudian.",
		depositFailed: "Deposit gagal. Sila cuba sebentar lagi. {error}",
		AllowOneDeposit: "Tiket deposit sebelumnya masih dalam proses.",
		AllowOneActiveWithdrawal: "Tiket pengeluaran sebelumnya masih dalam proses.",
		miniGameisOpened: "MiniGame sudah dibuka dalam tab lain",
		newVersion: "Versi baharu telah dikeluarkan, klik di sini untuk mengemas kini",
		popUpBlocked: "Penyekat Pop timbul didayakan, tidak dapat melancarkan game.",
		urlNotFound: "URL tidak ditemui.",
		InsufficientBalanceWithdrawal: "Baki tidak mencukupi untuk dikeluarkan",
		GameMaintance: "Permainan sedang diselenggara",
		launchGameError: "Pelancaran ralat permainan. Gagal mengambil URL.",
		referral: {
			errorMsg: {
				"521": "ID Rujukan tidak ditemui.",
				"522": "Bonus Rujukan tidak ditemui.",
				"523": "Tidak dapat menuntut Bonus Rujukan. Keperluan tidak dipenuhi. ",
				"524": "Tidak dapat menuntut Bonus Rujukan. Turnover wujud.",
				"525": "Tidak dapat menuntut Bonus Rujukan. Winover wujud.",
				"526": "Tiada Bonus Rujukan untuk dituntut.",
				"529": "Tuntutan gagal. Sila hubungi perkhidmatan pelanggan.",
			},
		},
		notificationGranted: "Anda telah mendayakan fungsi pemberitahuan.",
		notificationDenied:
			"Kebenaran untuk ciri pemberitahuan telah dimatikan. Sila dayakannya dalam tetapan peranti anda.",
		notificationAllow: "Ciri pemberitahuan anda telah diaktifkan.",
		notificationNotAllow: "Ciri pemberitahuan anda telah dinyahaktifkan",
		notificationNotSupport: "Pelayar anda tidak menyokong ciri pemberitahuan",
		cameraDenied: "Kebenaran kamera telah dinafikan. Sila dayakan dalam tetapan peranti anda.",
		cameraNotSupport: "Pelayar atau peranti anda tidak menyokong ciri ini",
		accountAccessed: "Anda akan dilog keluar akibat percubaan log masuk berganda. Sila log masuk semula.",
		toastOffline: "Nampaknya anda berada di luar talian. Sila semak sambungan rangkaian anda.",
	},
	notification: {
		granted: "Diaktifkan",
		denied: "Dinyahaktifkan",
		default: "Aktifkan Sekarang",
		notSupport: "Tidak Disokong",
	},
	about: {
		about1: `${brandName} Kasino menawarkan banyak permainan kasino paling popular yang anda akan dapati di beberapa kasino paling terkenal di seluruh dunia. Nikmati pengalaman kasino dalam talian yang selamat dan terjamin, di mana anda boleh bermain slot dalam talian, Poker, Baccarat, Roulette, Blackjack, dan banyak lagi permainan kasino dalam talian!`,
		title2: `SELAMAT DATANG KE KASINO DALAM TALIAN MALAYSIA YANG TOP & DIPERCAYAI: ${brandName}`,
		about2: `${brandName}, salah satu Kasino Dalam Talian yang terbaik di Malaysia. ${brandName} Casino menawarkan sebahagian daripada permainan kasino untuk dimainkan. Setiap pemain boleh menyertai kami untuk mendapatkan permainan kasino yang paling memuaskan.\n\nHiburkan diri anda dengan permainan kasino terbaik. Di sini semua permainan tersedia dengan bunyi dan tema yang berkualiti. Anda juga boleh mencuba nasib anda di permainan kasino dalam talian kami. Ikuti kami sekarang dan dapatkan peluang untuk memenangi ganjaran. Anda boleh mengakses semua permainan kasino dengan selesa di rumah anda.\n\nAdakah anda berasa bertuah hari ini? Jangan buang masa anda untuk berfikir lagi. Mulakan sahaja permainan anda dengan Pertaruhan Dalam Talian Malaysia yang terbaik. Kasino ${brandName} sedang menunggu anda untuk bermain pada bila-bila masa di mana-mana tempat.\n\nKasino ${brandName} mempunyai pelbagai jenis permainan terbaik dengan keselamatan yang tinggi. Apl dan tapak web permainan ${brandName} menggunakan penyulitan SSL 128-bit untuk memastikan taruhan anda selamat.`,
		aboutUs: `Memperkenalkan ${brandName}, sensasi terbaharu dalam perjudian dalam talian yang melanda Malaysia sejak dilancarkan pada Mei 2024. Dengan rangkaian permainan yang menarik termasuk GreatBlue, Safari Heat, Dolphin Reef, dan banyak lagi, ${brandName} menawarkan pengalaman perjudian yang mendebarkan yang mengingatkan Sky Casino di Genting Highlands.
		\nApa yang membezakan ${brandName} adalah kemudahannya yang tiada tandingan - main pada bila-bila masa, di mana-mana sahaja dengan menggunakan telefon mudah alih anda (Android/iOS), tablet, atau desktop. Dengan sokongan ciri "Tambah ke Laman Utama", nikmati kemudahan seperti aplikasi tanpa perlu memuat turun. Selain itu, sekatan umur yang ketat memastikan bahawa hanya pengguna yang berumur 18 tahun ke atas yang boleh menikmati sensasi perjudian ini.
		\nDi ${brandName}, keselamatan dan privasi anda adalah yang terpenting. Kami menyediakan platform yang sangat selamat dengan langkah-langkah yang teguh untuk melindungi data pengguna dan mengelakkan kebocoran data peribadi. Tetapi bukan itu sahaja - kami menawarkan ciri eksklusif seperti ${brandName}+, di mana anda boleh memperoleh faedah sehingga 3% setiap hari dengan hanya memasukkan wang tunai. Terokai ganjaran Check-in Harian kami dan Sistem Rujukan yang komprehensif, di mana memperoleh ganjaran untuk membawa masuk pemain baharu hanyalah permulaannya.
		\nTerokai pelbagai kategori permainan kami, termasuk Slot, Kasino Langsung, Memancing, Sukan, Sabung Ayam, 4D, Permainan Pantas, dan Permainan Mini. Manfaatkan permainan mini kami untuk membuka ganjaran percuma dan meningkatkan pengalaman permainan anda.
		\nSertailah kami dan mula menang dengan ${brandName} hari ini!`,
	},
	vip: {
		enhanceYourGaming: "Tingkatkan Pengalaman Permainan Anda:\nMelangkah Masuk ke Kasino Lounge VIP Premier.",
		experiencePrivilege: `ALAMI KEISTIMEWAAN VIP ${brandName}.`,
		vipIntro: `Dengan Program VIP baru kami, ahli yang setia boleh mendapatkan lebih banyak manfaat apabila bermain di ${brandName}. Ia bukan sekadar insentif, kami menawarkan promosi eksklusif hanya untuk VIP dan permainan bonus dengan pulangan terbaik.\n\nSelain eksklusiviti, ahli VIP adalah keutamaan utama kami. Dari urusan deposit dan pengeluaran hingga sebarang masalah permainan, semua permintaan VIP adalah yang pertama dalam senarai kami.`,
		vipMemberLevel: "Peringkat Keahlihan VIP",
		firstDeposit: "Deposit Pertama",
		minDeposit: "Deposit Min.",
		byInvitation: "Dengan Jemputan",
		vipPrivileges: "Keistimewaan VIP",
		vipBenefits: "Faedah VIP",
		upgradeBonus: "Bonus Naik Taraf",
		birthdayBonus: "Bonus Hari Jadi (Bayaran Maks)",
		dailyWithdrawalLimitation: "Had Pengeluaran Harian",
		dailyRescueBonus: "Bonus Penyelamat Harian",
		weeklyRescueBonus: "Bonus Penyelamat Mingguan",
		accountManager: "Pengurus Akaun Eksklusif",
		specialRebate: "Rebat Khas",
		slotsRebate: "Rebat Slot",
		liveCasinoRebate: "Rebat Live Casino",
		sportsRebate: "Rebat Sports",
		fishingRebate: "Rebat Fishing",
		upgradeRequirement: "Keperluan Peningkatan Tahap",
		minDepositWithinAMonth: "Minimum Deposit Dalam 1 Bulan",
		retentionRequirement: "Keperluan Penyelenggaran Tahap",
		lifetimeDeposit: "Deposit Terkumpul",
		lifetimeTurnover: "Turnover Terkumpul",
		membershipRenewal: "Pembaharuan Keahlian",
		lifetime: "Seumur Hidup",
		once: "Sekali",
		always: "Sentiasa",
		daily: "Harian",
		weekly: "Mingguan",
		monthly: "Bulanan",
		quarterly: "Suku",
		"half yearly": "Setengah Tahun",
		yearly: "Tahunan",
		vipExclusive: "VIP Eksklusif",
		compulsory: "Pengesahan Identiti Adalah Wajib untuk Peningkatan VIP",
		vipStatus: "Status VIP Semasa",
		increaseBalanceText: "Deposit sekarang untuk mencapai tahap VIP seterusnya",
		amountToReach: "Amaun yang diperlukan untuk dicapai",
		currentProgress: "Kemajuan semasa",
		welcomeBack: "Selamat kembali! Kesetiaan dan keterujaan anda amat dihargai.",
		totalDeposit: "Jumlah deposit",
		tnc: [
			{
				term: "Untuk naik taraf kali pertama, ahli dikehendaki menyediakan semua dokumen yang diperlukan untuk tujuan pengesahan. Selepas pengesahan selesai, status keahlian akan dikemaskini ke dalam sistem dalam tempoh 3 hari bekerja.\n\nLangkah-langkah pengesahan adalah seperti berikut:\n\ni. Sahkan Butiran Profil (Nama penuh, Nombor Telefon dan Alamat E-mel)\nii. Sahkan Aktiviti Akaun Permainan (Gaya Pertaruhan, Aktiviti Deposit & Pengeluaran)\n\nCatatan Penting: Sekiranya terdapat sebarang aktiviti yang mencurigakan atau tidak normal dalam tempoh pengesahan 3 hari, pihak pengurusan berhak menolak kelayakan untuk menaik taraf.",
			},
			{
				term: "Keistimewaan VIP boleh dinikmati serta-merta selepas menaik taraf ke peringkat seterusnya dan sah selama tiga bulan berdasarkan peringkat tertinggi yang dicapai.\n\nCatatan Penting: Untuk mengekalkan status keahlian anda, ahli dikehendaki mencapai keperluan yang sama dalam tempoh satu suku.",
			},
			{
				term: `Semua jemputan naik taraf status VIP akan dihantar oleh Bahagian VIP ${brandName}.`,
			},
			{
				term: `${brandName} berhak untuk mengalih keluar keistimewaan VIP akaun ahli, dan menurunkan taraf tahap VIP atau bahkan menamatkan mana-mana akaun yang dianggap mempunyai sebarang aktiviti yang mencurigakan atau tidak normal pada bila-bila masa tanpa sebarang notis terlebih dahulu.\n\nCatatan Penting: Setiap status keahlian tertakluk kepada penurunan taraf berdasarkan aktiviti akaun.`,
			},
			{
				term: `Semua keputusan pihak pengurusan ${brandName} adalah muktamad.`,
			},
		],
		faq: [
			{
				q: "Bagaimana untuk menjadi ahli VIP",
				a: "Anda perlu mendepositkan sekurang-kurangnya RM10,000 dalam tempoh satu suku (1 bulan) untuk menjadi Ahli Silver.",
			},
			{
				q: "Berapa banyak peringkat yang ada untuk program VIP?",
				a: "Terdapat sejumlah 6 peringkat. Program VIP terdiri daripada peringkat Bronze, Silver, Gold, Platinum dan Royal.",
			},
			{
				q: "Bagaimanakah cara untuk saya naik taraf ke peringkat seterusnya?",
				a: "Anda perlu mendepositkan jumlah minimum yang diperlukan oleh setiap peringkat ke dalam akaun anda. Sebagai contoh, jika anda kini Ahli Bronze, anda perlu mendepositkan sekurang-kurangnya RM10,000 untuk menjadi Ahli Silver, dan daripada Ahli Silver, anda perlu mendepositkan sekurang-kurangnya RM40,000 lagi dalam tempoh 30 hari untuk menjadi Ahli Gold.",
			},
			{
				q: "Bagaimanakah saya mengekalkan status keahlian VIP saya?",
				a: "Untuk mengekalkan status peringkat ahli anda untuk tahun berikutnya, anda perlu mendepositkan jumlah yang diperlukan pada suku terakhir sebelum tarikh tamat tempoh. Sebagai contoh, jika status Ahli Gold anda sah dari Januari - Disember, anda perlu mencapai deposit RM25,000 menjelang suku akhir tahun (Oktober) untuk mengekalkan tahap sedia ada anda untuk tahun berikutnya. Walau bagaimanapun, status keahlian peringkat Bronze dan Silver adalah sah seumur hidup.",
			},
			{
				q: "Adakah terdapat yuran tahunan atau bulanan untuk menjadi sebahagian daripada ahli VIP?",
				a: "Tidak. Program ini diwujudkan untuk mempromosikan semua ahli setia yang aktif menyokong pengalaman permainan kami sejak tarikh berdaftar.",
			},
		],
		nextTierProgress: "Progres Tingkat Berikutnya",
		choosePlan: "Pilih paket VIP Anda",
		upgradeNow: "Tingkatkan sekarang",
		upgrading: "Tahniah, anda akan ditingkatkan tidak lama lagi!",
		rebateMaxPayout: "Bonus Rebat Harian (Bayaran Maks)",
		rebate: "Rebat",
	},
	error: {
		InvalidWithdrawalAmount: "Amaun pengeluaran tidak sah",
		ReachDailyLimit: "Melebihi had harian",
		TurnoverExceeded: "Melebihi turnover",
		WithdrawalFailed: "Pengeluaran gagal, sila hubungi Sokongan Pelanggan.",
		MemberNameExist: "Nama ahli wujud, sila hubungi Sokongan Pelanggan.",
	},
	addMessages: {
		ios1: "1. Pada penyemak imbas iOS, buka Menu Kongsi",
		ios2: '2. Ketik pada butang "Tambah ke Skrin Utama".',
		android: `1. Buka parameter penyemak imbas
  2. Ketik pada "Tambah ke skrin utama"`,
		windows: {
			chrome: "Klik pada butang (+) di sebelah kanan bar navigasi anda.",
			firefox: `1. Ubah saiz penyemak imbas anda supaya anda boleh melihat desktop anda
  2. Seret dan lepas butang (i) kiri bar navigasi anda ke desktop anda`,
		},
		macos: {
			chrome: "Klik pada butang (+) di sebelah kanan bar navigasi anda.",
			safari: `1. Ubah saiz penyemak imbas anda supaya anda boleh melihat desktop anda
  2. Seret & lepas ikon bumi sebelah kiri bar pemberitahuan anda ke desktop anda`,
			firefox: `1. Ubah saiz penyemak imbas anda supaya anda boleh melihat desktop anda
  2. Seret dan lepas butang (i) kiri bar navigasi anda ke desktop anda`,
		},
		others: "Nampaknya penyemak imbas anda tidak menyokong penambahan pada skrin utama secara asli. Jangan ragu untuk mengemas kini/menukar penyemak imbas anda.",
	},
	terms,
	privacyPolicy,
	responsible,
	goPlusTerms,

	// ================= STATEMENT =================

	statement: {
		id: "ID",
		method: "Kaedah",
		amount: "Jumlah",
		date: "Tarikh",
		status: "Status",
		receipt: "Resit",
		remarks: "Pernyataan",
		transactionType: "Taip",
		transactionCount: "Kiraan Transaksi",
		vendorFrom: "Dari",
		vendorTo: "Kepada",
		betId: "ID Pertaruhan",
		gameName: "Permainan",
		turnover: "Turnover",
		winOrLoss: "Menang/kerugian",
		bonusName: "Bonus",
		bonusAmount: "Jumlah",
		group: "Kumpulan",
		targetAmount: "Jumlah sasaran",
		New: "Rekod Baru",
		Success: "Sukses",
		Processing: "Pemrosesan",
		Rejected: "Ditolak",
		RUNNING: "DALAM PROSES",
		COMPLETED: "SELESAI",
		CANCELLED: "DIBATALKAN",
		SLOTS: "Bonus Slot",
		"LIVE CASINO": "Bonus Live Casino",
		FISHING: "Bonus Fishing",
		SPORTS: "Bonus Sports",
		COCKFIGHT: "Bonus Sabung Ayam",
		LOTTERY: "Bonus 4D",
		"FAST GAME": "Bonus Fast Game",
		"NEW MEMBER": "Ahli Baru",
		REFERRAL: "Bonus Rujukan",
		cashIn: "Amaun Masuk",
		cashOut: "Amaun Keluar (Prinsipal)",
		cashOutLock: "Amaun Keluar (Faedah)",
		MAIN: "Dompet Utama",
		Interest: `${brandName}+`,
		LOCK: "Dompet Terkunci",

		ReferralAdj: "Bonus Pelarasan",
		RegBonus: "Bonus Daftar",
		DepositAdj: "Pelarasan Deposit",
		WithdrawalAdj: "Pelarasan Pengeluaran",
		RebateAdj: "Rebat",
		MiniGamesAdj: "Bonus Mini Game",
		RescueAdj: "Bonus Penyelamat",
		BirthdayAdj: "Bonus Hari Lahir",
		RankUpAdj: "Bonus Naik Pangkat",
		CheckInAdj: "Kredit Percuma Acara",
		ChipDepositAdj: "Pelarasan Deposit Cip",
		ChipWithdrawalAdj: "Pelarasan Pengeluaran Cip",
		"BO Deposit": "Deposit BO",
		AutoDeposit: "Deposit Auto",
		DepositReferral: "Deposit Rujukan",
		ManualDeposit: "Deposit Manual",
		Withdrawal: "Pengeluaran",
		DAILY: "Harian",
		WEEKLY: "Mingguan",
		LC: "Live Casino",
		SL: "Slots",
		SP: "Sports",
		FH: "Fishing",
		LT: "4D",
		CF: "Sabung Ayam",
		FG: "Fast Game",
	},
};
