<template>
	<Transition name="fade-out" mode="out-in" :duration="500">
		<div
			v-if="!forceEndLoading && (isRouterLoading || delayLoading)"
			class="loadingScreen overlay"
			:class="{ android: isAndroid }"
		>
			<div class="loadingAnimation">
				<img src="@/assets/icons/logo_transparent.png" alt="" class="logo" />
				<div class="loader"></div>
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { isAndroid } from "@/composables/usePlatform";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();

const MIN_LOADING_DURATION_SEC = 1.5;
const MAX_LOADING_DURATION_SEC = 5;

const isRouterLoading = computed(() => store.getters["progressTracker/getAppLoading"]);
const delayLoading = ref(false);
const forceEndLoading = ref(false);

watch(isRouterLoading, (newState) => {
	if (newState) {
		delayLoading.value = true;
		setTimeout(() => (delayLoading.value = false), MIN_LOADING_DURATION_SEC * 1000);

		forceEndLoading.value = false;
		setTimeout(() => (forceEndLoading.value = true), MAX_LOADING_DURATION_SEC * 1000);
	}
});
</script>

<style lang="sass" scoped>
.loadingScreen
	background: var(--background-inverted)
	z-index: 2000

.loadingAnimation
	position: relative
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column
	gap: 2rem
@media (display-mode: standalone)
	.android
		display: none
	.loadingAnimation
		top: 45%

.logo
	width: 5rem

.loader
	height: 3px
	width: 5rem
	--c: no-repeat linear-gradient(var(--border-highlight) 0 0)
	background: var(--c), var(--c), var(--background-semitransparent-light)
	background-size: 60% 100%
	animation: l16 3s infinite
html[data-theme="dark"]
	.loader
		--c: no-repeat linear-gradient(var(--border-secondary) 0 0)

@keyframes l16
	0%
		background-position: -150% 0, -150% 0
	50%
		background-position: 250% 0, -150% 0
	100%
		background-position: 250% 0, 250% 0

.fade-out-leave-active
	transition: opacity 0.5s ease

.fade-out-leave-to
	opacity: 0
</style>
