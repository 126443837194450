import { MemberBankInfoResponse } from "@/types/Member/Response/MemberBankInfoResponse";
import { MemberResponse } from "@/types/Member/Response/MemberResponse";
import { AspUserClientResponse } from "@/types/Member/Response/AspUserClientResponse";
import { MemberReferralResponse, MemberReferralListResponse } from "@/types/Member/Response/MemberReferralResponse";

export interface MemberStateInterface {
	memberBank: MemberBankInfoResponse;
	member: MemberResponse;
	memberCount: number;
	aspUserClient: AspUserClientResponse;
	onLoadMemberBank: boolean;
	isMemberBankExist: boolean;
	memberReferral: MemberReferralResponse;
	memberReferralList: MemberReferralListResponse[];
}

function state(): MemberStateInterface {
	return {
		memberBank: {} as MemberBankInfoResponse,
		member: {} as MemberResponse,
		memberCount: 0,
		aspUserClient: {} as AspUserClientResponse,
		onLoadMemberBank: false,
		isMemberBankExist: false,
		memberReferral: {} as MemberReferralResponse,
		memberReferralList: [] as MemberReferralListResponse[],
	};
}

export default state;
