<!-- Use this component for all links/modals -->

<template>
	<span v-if="showLoginModal" @click="openLoginPage()" class="unifiedLink cursorPointer">
		<slot> </slot>
	</span>

	<span v-else-if="showBankModal" @click="openBankInfoPage()" class="unifiedLink cursorPointer">
		<slot> </slot>
	</span>

	<router-link v-else-if="showRouteLink" :to="{ path: link, query: routerQuery }" class="unifiedLink cursorPointer">
		<slot> </slot>
	</router-link>

	<span v-else-if="showModal" @click="openModal(modalName)" class="unifiedLink cursorPointer">
		<slot> </slot>
	</span>

	<div v-else-if="showEvent" class="unifiedLink cursorPointer" @click="emit('linkClick')">
		<slot></slot>
	</div>

	<a v-else-if="showLink" :href="link" class="unifiedLink cursorPointer" target="_blank">
		<slot></slot>
	</a>

	<div v-else class="unifiedLink">
		<slot></slot>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { isLogin } from "@/composables/useAuth";
import store from "@/store/index";
import { routeData } from "@/constant";

const emit = defineEmits(["linkClick"]);
const router = useRouter();
const route = useRoute();

const login = routeData.find((item) => item.title == "login");
const bankInfo = routeData.find((item) => item.title == "bankinfo");

const props = defineProps({
	isRouteLink: {
		type: Boolean,
		default: false,
	},
	link: {
		type: [Object, String],
		default: null,
	},
	routerQuery: {
		type: Object,
		default: null,
	},
	isModal: {
		type: Boolean,
		default: false,
	},
	isEvent: {
		type: Boolean,
		default: false,
	},
	modalName: {
		type: String,
		default: null,
	},
	validateLogin: {
		type: Boolean,
		default: false,
	},
	validateBank: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

// ================= Modal Control Start =================

const openModal = (modalName) => {
	store.dispatch("modal/openModal", modalName);
};

// ================= Modal Control End =================

// ================= Show Control Start =================

const showLoginModal = computed(() => {
	return props.validateLogin && !isLogin.value && !props.disabled;
});

const openLoginPage = () => {
	router.push({
		path: login.link,
		query: { ...route.query, redirect: props?.link ? encodeURIComponent(props.link) : undefined },
	});
};

const openBankInfoPage = () => {
	router.push({
		path: bankInfo.link,
		query: { ...route.query, redirect: props?.link ? encodeURIComponent(props.link) : undefined },
	});
};

const showBankModal = computed(() => {
	return props.validateBank && !memberBankExists.value && !props.disabled;
});

const showRouteLink = computed(() => {
	return props.isRouteLink && props.link && !props.disabled;
});

const showModal = computed(() => {
	return props.isModal && props.modalName && !props.disabled;
});

const showEvent = computed(() => {
	return props.isEvent && !props.disabled;
});

const showLink = computed(() => {
	return props.link && !props.disabled;
});

const memberBankExists = computed(() => store.getters["member/getIsMemberBankExist"]);

// ================= Show Control End =================
</script>

<style scoped lang="sass">
.unifiedLink, .unifiedLink:hover
  display: contents
  text-decoration: none
  font-weight: revert
  margin: revert
</style>
