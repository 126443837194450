import { InterestStateInterface } from "./state";
import { MutationTree } from "vuex";

export enum InterestMutations {
	SET_PAYOUT_HISTORY = "SET_PAYOUT_HISTORY",
	SET_INTEREST_SETTING = "SET_INTEREST_SETTING",
}

export const mutations: MutationTree<InterestStateInterface> = {
	[InterestMutations.SET_PAYOUT_HISTORY](state, payload) {
		state.payoutHistory = payload;
	},
	[InterestMutations.SET_INTEREST_SETTING](state, payload) {
		state.interestSetting = payload;
	},
};
