import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { BonusStateInterface } from "./state";
import { BonusResponse, Description } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { ActiveBonusAppliedResponse } from "@/types/Bonus/Response/ActiveBonusAppliedResponse";
import { MemberGroupBonusResponse } from "@/types/Bonus/Response/MemberGroupBonusResponse";
import moment from "moment";
import { languageCodeToId } from "@/composables/useCulture";
import { serverISOStringToDateGMT, clientTimeNow } from "@/composables/useCountry";
import { PromotionBonusResponse } from "@/types/Bonus/Response/PromotionBonusResponse";

// const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<BonusStateInterface, RootState> = {
	getAllBonusList(state): PromotionBonusResponse[] {
		const dateNow = moment.utc(new Date()).format();
		const bonusList = state.promotionBonus
			.filter(
				(bonus) =>
					bonus.status == 1 &&
					moment(dateNow).isAfter(moment(bonus.startDate)) &&
					moment(dateNow).isBefore(moment(bonus.endDate))
			)
			.sort(function (a, b) {
				return a.priority - b.priority;
			});
		return bonusList;
	},
	getBonusList(state): PromotionBonusResponse[] {
		const dateNow = moment.utc(new Date()).format();
		const bonusList = state.promotionBonus
			.filter(
				(bonus) =>
					bonus.status == 1 &&
					moment(dateNow).isAfter(moment(bonus.startDate)) &&
					moment(dateNow).isBefore(moment(bonus.endDate)) &&
					bonus.showFE
			)
			.sort(function (a, b) {
				return a.priority - b.priority;
			});
		return bonusList;
	},
	getBonusById(state) {
		return (bonusId: number): any => {
			return state.bonusList.find((bonus) => bonus.id == bonusId);
		};
	},
	getMemberBonus(state) {
		return (languageCode = "en"): BonusResponse[] => {
			return state.memberBonus
				.filter((bonus) => bonus.status == 1 && bonus.showDeposit)
				.map((bonus) => {
					return {
						...bonus,
						name: bonus.description.find((x) => x.languageId == languageCodeToId[languageCode])?.title || bonus.name,
					};
				})
				.sort(function (a, b) {
					return a.priority - b.priority;
				});
		};
	},
	getMemberPreBonusList(state): MemberGroupBonusResponse[] {
		return state.memberGroupBonusResponse.sort(function (a, b) {
			return a.priority - b.priority;
		});
	},
	getBonusClaimed(state): BonusClaimedResponse[] {
		let bonusStatement = state.bonusClaimed;
		bonusStatement.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
		bonusStatement = bonusStatement.map((bonusItem) => {
			const createDate = serverISOStringToDateGMT(bonusItem.created);
			bonusItem.created = createDate;
			return bonusItem;
		});
		return bonusStatement;
	},
	getUniqueCode(state): UniqueCodeResponse[] {
		return state.uniqueCode;
	},
	getActiveBonusApplied(state): ActiveBonusAppliedResponse[] {
		return state.activeBonusApplied;
	},
	getPromotionBonus(state): PromotionBonusResponse[] {
		return state.promotionBonus;
	},
};
