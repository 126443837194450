import httpClient, { ClientId, ClientName } from "./HttpClient";
import { GetSeamlessLaunchGameUrlRequest } from "@/types/Games/Request/GetSeamlessLaunchGameUrlRequest";
import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";

const getLaunchGameUrl = (
	vendorId: number,
	memberId: string,
	category: string,
	lang: string,
	gameCode: string,
	isFun: boolean,
	isMobile: boolean
): Promise<string> => {
	const endPoint = `/api/Games/LaunchGame`;
	const params = {
		memberId: memberId,
		vendorId: vendorId,
		category: category,
		lang: lang,
		gameCode: gameCode,
		isFun: isFun.toString(),
		isMobile: isMobile.toString(),
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameList = (vendorId: number, gamesCategory: string): Promise<GamesListResponse[]> => {
	const endPoint = `/api/Games`;
	const params = {
		vendorId: vendorId,
		gamesCategory: gamesCategory,
		clientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameCategories = (vendorId: number): Promise<string[]> => {
	const endPoint = `/api/Games/${vendorId}/Categories`;
	const params = {
		vendorId: vendorId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getSeamlessLaunchGameUrl = (
	vendorName: string,
	memberId: string,
	lang: string,
	category: string,
	gameCode: string,
	isMobile: boolean
): Promise<string> => {
	const endPoint = `/LaunchGame/${ClientName}/${vendorName}`;
	const request: GetSeamlessLaunchGameUrlRequest = {
		memberId: memberId,
		gameCode: gameCode,
		lang: lang,
		isFun: false,
		category: category,
		isMobile: isMobile,
	};
	Object.assign(httpClient.defaults, { headers: { "Content-Type": "application/json" } });
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

export { getLaunchGameUrl, getGameList, getGameCategories, getSeamlessLaunchGameUrl };
