import { ActionTree } from "vuex";
import rebateService from "@/network/RebateService/Rebate";
import { RootState } from "@/store/state";
import { RebateTierMutations } from "./mutations";
import { RebateGroupStateInterface } from "./state";

export const actions: ActionTree<RebateGroupStateInterface, RootState> = {
    async fetchRebateTier(context) {
        const res = await rebateService.getRebate();
        context.commit(RebateTierMutations.SET_REBATE_TIER, res);
        return res;
      },

    async fetchMemberGroupRebate(context) {
        const res = await rebateService.getMemberGroupRebate();
        context.commit(RebateTierMutations.SET_MEMBER_GROUP_REBATE, res);
        return res;
    }

};
