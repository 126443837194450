import httpClient, { ClientId } from "./HttpClient";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";

const getVendor = (clientId?:string): Promise<VendorResponse[]> => {
    const currentClientId = clientId == undefined ? ClientId : clientId;
    const endPoint =  `/api/Vendors`;
    const params = {
        clientId: currentClientId,
        active : true
    };
  
    return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getVendorAccount = (vendorId: number): Promise<VendorAccountResponse> => {
    const endPoint =  `/api/VendorAccounts/${vendorId}`;
    const params = {
        clientId: ClientId
    };
    return httpClient.get(endPoint,{ params }).then((response) => response.data);
};

const getCategoryList = (): Promise<CategoryResponse[]> => {
    const endPoint =  `/api/Vendors/Categories/${ClientId}`;
    return httpClient.get(endPoint).then((response) => response.data);
};

const getMemberActiveVendorList = (memberId: string): Promise<VendorResponse[]> => {
    const endPoint =  `/api/Vendors/GetMemberActiveVendorList`;
    const params = {
        clientId: ClientId,
        memberId : memberId
    };
    return httpClient.get(endPoint,{ params }).then((response) => response.data);
};



export default {
    getVendor,
    getVendorAccount,
    getCategoryList,
    getMemberActiveVendorList
};
