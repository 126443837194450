// Unregister service worker
// Domain changed from win-n-go.com to wngo888.com - 12/09/2023
// Domain changed from wngo888.com to gogonwin.site - 19/09/2023
// Remove in future when appropriate
if (!window.location.hostname.includes("gogonwin.site") && "serviceWorker" in navigator) {
	navigator.serviceWorker.getRegistrations().then(function (registrations) {
		for (const registration of registrations) {
			registration.unregister();
			console.log("Old domain service worker has been unregistered.");
		}
	});
}

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/store/index";
import BootstrapVue3 from "bootstrap-vue-3";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { dom } from "@fortawesome/fontawesome-svg-core";
import Vue3MobileDetection from "vue3-mobile-detection";
import vueI18n from "@/resource/index";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import toast, { useToast, POSITION } from "vue-toastification";
import Vue3Marquee from "vue3-marquee";
import VueLoading from "vue-loading-overlay";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import vSelect from "vue-select";
import VueCookies from "vue3-cookies";
import VOtpInput from "@hauzhengyi/vue3-otp-input";
import VueDragscroll from "vue-dragscroll";
import PrimeVue from "primevue/config";
import Vue3TouchEvents from "vue3-touch-events";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "vue-toastification/dist/index.css";
import "vue3-marquee/dist/style.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-select/dist/vue-select.css";
import "./styles/global.sass";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(BootstrapVue3);
app.use(vueI18n);
app.use(toast, {
	position: POSITION.BOTTOM_CENTER,
	transition: "Vue-Toastification__fade",
	maxToasts: 3,
});
app.use(VueLoading);
app.use(Vue3Marquee);
app.provide("toasted", useToast);
app.use(Vue3MobileDetection);
app.use(VueClipboard);
app.use(VueCookies);
app.use(VueDragscroll);
app.use(PrimeVue);
app.use(Vue3TouchEvents);
// app.use(IonicVue);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("base-image", BaseImage);
app.component("v-select", vSelect);
app.component("v-otp-input", VOtpInput);

//SET GLOBAL PROPERTIES
app.config.globalProperties.toast = useToast();

app.mount("#app");
