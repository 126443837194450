import { ActionTree } from "vuex";
import { cashIn, cashOut, fetchInterestPayoutHistory, fetchInterestSetting } from "@/network/InterestService/Interest";
import { InterestStateInterface } from "./state";
import { RootState } from "@/store/state";
import { InterestMutations } from "./mutations";
import { clientDateStringToServerISOString } from "@/composables/useCountry";

export const actions: ActionTree<InterestStateInterface, RootState> = {
	async cashIn(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await cashIn(loginId, payload.amount);
	},

	async cashOut(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await cashOut(loginId);
	},

	async fetchInterestPayoutHistory(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await fetchInterestPayoutHistory(
			clientDateStringToServerISOString(payload.dateFrom),
			clientDateStringToServerISOString(payload.dateTo, true),
			loginId
		);
		context.commit(InterestMutations.SET_PAYOUT_HISTORY, res);
	},
	async fetchInterestSetting(context) {
		const res = await fetchInterestSetting();
		context.commit(InterestMutations.SET_INTEREST_SETTING, res);
	},
};
