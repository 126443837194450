import { InterestPayoutHistoryResponse, InterestSettingResponse } from "@/types/Interest/Response/InterestResponse";

export interface InterestStateInterface {
	payoutHistory: InterestPayoutHistoryResponse[];
	interestSetting: InterestSettingResponse;
}

function state(): InterestStateInterface {
	return {
		payoutHistory: [] as InterestPayoutHistoryResponse[],
		interestSetting: {} as InterestSettingResponse,
	};
}

export default state;
