import { EventStateInterface } from "./state";
import { GetEventResponse } from "@/types/Event/Response/GetEventResponse";
import { MemberLoginEventResponse } from "@/types/Event/Response/MemberLoginEventResponse";
import { MemberRecheckInResponse } from "@/types/Event/Response/MemberRecheckInResponse";
import { GetMemberEventReportResponse } from "@/types/Event/Response/GetMemberEventReportResponse";
import { AddOnBonusResponse } from "@/types/Event/Response/AddOnBonusResponse";
import { GetUserDepositAmountResponse } from "@/types/Payment/Response/GetUserDepositAmountResponse";
import { MutationTree } from "vuex";

export enum EventMutations {
	SET_EVENT_LIST = "SET_EVENT_LIST",
	SET_MEMBER_LOGIN_EVENT = "SET_MEMBER_LOGIN_EVENT",
	SET_MEMBER_RECHECKIN = "SET_MEMBER_RECHECKIN",
	SET_MEMBER_EVENT_REPORT = "SET_MEMBER_EVENT_REPORT",
	SET_EVENT_REWARD_BY_DAY = "SET_EVENT_REWARD_BY_DAY",
	SET_EVENT_CLAIMED_BY_DAY = "SET_EVENT_CLAIMED_BY_DAY",
	SET_MEMBER_ADD_ON_BONUS = "SET_MEMBER_ADD_ON_BONUS",
	SET_MEMBER_RECHECKIN_BY_DAY = "SET_MEMBER_RECHECKIN_BY_DAY",
	SET_USER_DEPOSIT_AMOUNT = "SET_USER_DEPOSIT_AMOUNT",
}

export const mutations: MutationTree<EventStateInterface> = {
	[EventMutations.SET_EVENT_LIST](state, payload: GetEventResponse[]) {
		state.eventList.push(...payload);
	},
	[EventMutations.SET_MEMBER_LOGIN_EVENT](state, payload: MemberLoginEventResponse) {
		state.memberLoginEvent = payload;
	},
	[EventMutations.SET_MEMBER_RECHECKIN](state, payload: MemberRecheckInResponse[]) {
		state.memberRecheckInList.push(...payload);
	},
	[EventMutations.SET_MEMBER_EVENT_REPORT](state, payload: GetMemberEventReportResponse[]) {
		state.memberEventReport.push(...payload);
	},
	[EventMutations.SET_EVENT_REWARD_BY_DAY](state, payload: []) {
		state.eventRewardByDay = payload;
	},
	[EventMutations.SET_EVENT_CLAIMED_BY_DAY](state, payload: []) {
		state.eventClaimedByDay = payload;
	},
	[EventMutations.SET_MEMBER_ADD_ON_BONUS](state, payload: AddOnBonusResponse) {
		state.memberAddOnBonus = payload;
	},
	[EventMutations.SET_MEMBER_RECHECKIN_BY_DAY](state, payload: []) {
		state.memberRecheckInByDay = payload;
	},
	[EventMutations.SET_USER_DEPOSIT_AMOUNT](state, payload: GetUserDepositAmountResponse[]) {
		state.userDepositAmount = payload;
	},
};
