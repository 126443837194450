import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";
import { BonusBirthdayResponse } from "@/types/Payment/Response/BonusBirthdayResponse";

export interface PaymentStateInterface {
	depositStatement: StatementResponse[];
	withdrawalStatement: StatementResponse[];
	transferStatement: StatementResponse[];
	adjustment: StatementResponse[];
	bonusReferral: BonusReferralResponse[];
	bonusBirthday: BonusBirthdayResponse;
	interestStatement: StatementResponse[];
}

function state(): PaymentStateInterface {
	return {
		depositStatement: [] as StatementResponse[],
		withdrawalStatement: [] as StatementResponse[],
		transferStatement: [] as StatementResponse[],
		adjustment: [],
		bonusReferral: [] as BonusReferralResponse[],
		bonusBirthday: {} as BonusBirthdayResponse,
		interestStatement: [] as StatementResponse[],
	};
}

export default state;
