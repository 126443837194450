import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { RebateGroupStateInterface } from "./state";
import {
    TierSettingGroupByTierResponse,
    RebateJobSettingMemberGroup,
} from "@/types/Rebate/Response/RebateResponse";

export const getters: GetterTree<RebateGroupStateInterface, RootState> = {
  getTierRebate(state): TierSettingGroupByTierResponse[] {
    return state.tierGroup;
  },
  getMemberGroupRebate(state): RebateJobSettingMemberGroup[] {
    return state.memberGroupRebate;
  }
};