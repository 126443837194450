import httpClient, { ClientId } from "./HttpClient";
import { MemberVendorBalanceResponse } from "@/types/MemberAccount/Response/MemberVendorBalanceResponse";
import { MemberWinoverResponse } from "@/types/MemberAccount/Response/MemberWinoverResponse";
import { MemberTurnoverResponse } from "@/types/MemberAccount/Response/MemberTurnoverResponse";
import { MemberAccountResponse } from "@/types/MemberAccount/Response/MemberAccountResponse";
import { ResetWinoverRequest } from "@/types/MemberAccount/Request/ResetWinoverRequest";
import { UpdateGamePasswordRequest } from "@/types/MemberAccount/Request/UpdateGamePasswordRequest";
import { MemberWalletResponse } from "@/types/MemberAccount/Response/MemberWalletResponse";
import { encrypt } from "@/composables/useEncryption";

const getVendorBalance = (
	vendorAccountId: number,
	memberId: string,
	loginId: string,
	isFromDB: boolean
): Promise<MemberVendorBalanceResponse> => {
	const endPoint = `/api/MemberAccounts/MemberVendorBalance/${vendorAccountId}`;
	const params = {
		memberId: memberId,
		logindId: loginId,
		isFromDB: isFromDB,
		clientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberWinover = (memberId: string, clientId?: string): Promise<MemberWinoverResponse> => {
	const currentClientId = clientId == undefined ? ClientId : clientId;
	const endPoint = `/api/MemberAccounts/MemberAccountWinover/${currentClientId}`;
	const params = {
		clientId: currentClientId,
		memberId: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberTurnover = (memberId: string, clientId?: string): Promise<MemberTurnoverResponse> => {
	const currentClientId = clientId == undefined ? ClientId : clientId;
	const endPoint = `/api/MemberAccounts/MemberAccountTurnover/${currentClientId}`;
	const params = {
		clientId: currentClientId,
		memberId: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberAccount = (vendorAccountId: number, memberId: string): Promise<MemberAccountResponse> => {
	const endPoint = `/api/MemberAccounts`;
	const params = {
		vendorAccountId: vendorAccountId,
		memberId: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const resetWinover = (winover: ResetWinoverRequest) => {
	const endPoint = `/api/MemberAccounts/ResetMemberAccountWinover`;
	const request: ResetWinoverRequest = {
		group: winover.group,
		winover: winover.winover,
		lockdown: winover.lockdown,
		memberId: winover.memberId,
		clientId: winover.clientId,
	};
	httpClient.post(endPoint, JSON.stringify(request));
};

const updateGamePassword = (
	loginId: string,
	memberId: string,
	vendorId: number,
	vendorGameLoginId: string,
	vendorGamePassword: string,
	phone: string,
	newPassword: string
): Promise<MemberAccountResponse> => {
	const endPoint = `/api/MemberAccounts/UpdateMemberAccPass`;
	const request: UpdateGamePasswordRequest = {
		loginId: loginId,
		memberId: memberId,
		vendorId: vendorId,
		vendorGameLoginId: vendorGameLoginId,
		vendorGamePassword: vendorGamePassword,
		phone: phone,
		newPassword: newPassword,
		clientId: ClientId,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getMemberWallet = (memberId: string): Promise<MemberWalletResponse> => {
	const endPoint = `/api/MemberWallet`;
	let params;
	if (process.env.NODE_ENV === "development")
		params = {
			memberId: memberId,
		};
	else
		params = {
			hash: encrypt({
				MemberID: memberId,
				ClientId: ClientId,
			}),
		};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getVendorBalance,
	getMemberWinover,
	getMemberTurnover,
	getMemberAccount,
	resetWinover,
	updateGamePassword,
	getMemberWallet,
};
