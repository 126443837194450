import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import state, { VendorStateInterface } from "./state";
import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";
import { testerVendorList } from "@/common/GameType";

export const getters: GetterTree<VendorStateInterface, RootState> = {
	getVendor(state, _, rootState, rootGetters): VendorResponse[] {
		const memberTag = rootGetters["member/getMember"]?.memberTags?.[0]?.tagInfo?.name;

		return state.vendor.filter(
			(item) =>
				!testerVendorList.includes(item.name) || (testerVendorList.includes(item.name) && memberTag == "Tester")
		);
	},
	getActiveMemberVendor:
		(state) =>
		(vendorName: string): VendorResponse[] => {
			if (vendorName) {
				return state.activeMemberVendor.filter((vendor) => vendor.name == vendorName);
			} else return state.activeMemberVendor;
		},
	getVendorAccount(state): VendorAccountResponse {
		return state.vendorAccount;
	},
	getCategory:
		(state) =>
		(categoryName: string): CategoryResponse => {
			return state.categoryList.filter((category) => category.name == categoryName)[0];
		},
	getCategoryList(state): CategoryResponse[] {
		return state.categoryList.filter((category) => category.status == true);
	},
};
