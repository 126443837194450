import {
    TierSettingGroupByTierResponse,
    RebateJobSettingMemberGroup,
  } from "@/types/Rebate/Response/RebateResponse";
  
  export interface RebateGroupStateInterface {
    tierGroup: TierSettingGroupByTierResponse[];
    memberGroupRebate: RebateJobSettingMemberGroup[];
  }
  
  function state(): RebateGroupStateInterface {
    return {
        tierGroup: [] as TierSettingGroupByTierResponse[],
        memberGroupRebate : [] as RebateJobSettingMemberGroup[],
    };
  }
  
  export default state;