import httpClient, { ClientId, ClientSecret } from "./HttpClient";
import { InterestPayoutHistoryResponse, InterestSettingResponse } from "@/types/Interest/Response/InterestResponse";
import { CashInRequest, CashOutRequest, InterestPayoutHistoryRequest } from "@/types/Interest/Request/InterestRequest";

const cashIn = (loginId: string, amount: number): Promise<any> => {
	const endPoint = "/api/InterestWallet/CashIn";
	const request: CashInRequest = {
		loginId: loginId,
		amount: amount,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const cashOut = (loginId: string): Promise<any> => {
	const endPoint = "/api/InterestWallet/CashOut";
	const request: CashOutRequest = {
		loginId: loginId,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const fetchInterestPayoutHistory = (
	dateFrom: string,
	dateTo: string,
	loginId: string
): Promise<InterestPayoutHistoryResponse> => {
	const endPoint = "/api/InterestWallet/InterestPayoutHistory";
	const params = {
		dateFrom: dateFrom,
		dateTo: dateTo,
		loginId: loginId,
		status: 1,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const fetchInterestSetting = (): Promise<InterestSettingResponse> => {
	const endPoint = "/api/InterestWallet/InterestSetting";
	return httpClient.get(endPoint).then((response) => response.data);
};

export { cashIn, cashOut, fetchInterestPayoutHistory, fetchInterestSetting };
