import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { GamesStateInterface } from "./state";
import { GamesListResponse } from "@/types/Games/Response/GamesListResponse";

export const getters: GetterTree<GamesStateInterface, RootState> = {
	getGameList(state): GamesListResponse[] {
		return state.gameLists
			.filter((x) => (x.isActive == true && x.supportWalletType == 0) || x.supportWalletType == 2)
			.sort((a, b) => {
				if (a.seqNo == null && b.seqNo != null) return 1;
				else if (b.seqNo == null && a.seqNo != null) return -1;
				else return a.seqNo - b.seqNo;
			});
	},
};
