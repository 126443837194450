import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { InterestStateInterface } from "./state";
import { serverISOStringToDateGMT } from "@/composables/useCountry";

export const getters: GetterTree<InterestStateInterface, RootState> = {
	getPayoutHistory(state) {
		return state.payoutHistory.map((payout) => {
			return { ...payout, createdDate: serverISOStringToDateGMT(payout.createdDate) };
		});
	},
	getInterestSetting(state) {
		return state.interestSetting;
	},
};
