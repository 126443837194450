<template>
	<Transition name="fade" mode="out-in" :duration="500">
		<!-- ================= ANNOUNCEMENT ================= -->
		<div class="overlay" v-if="showAnnouncement && isHomePage && !isLogin">
			<div class="announcementDialog">
				<div class="announcementContent container noScrollBar">
					<!-- BANNER -->
					<div class="announcementImageContainer" v-if="languageFilteredAnnouncement[0].fileName">
						<BaseImage
							:imgSrc="languageFilteredAnnouncement[0].fileName"
							:isFullUrl="true"
							class="announcementImage"
							decoding="sync"
						/>
					</div>
					<!-- BANNER -->

					<!-- ANNOUNCEMENT TEXT -->
					<div v-html="languageFilteredAnnouncement[0].content" class="announcementTextContent"></div>
					<!-- ANNOUNCEMENT TEXT -->

					<!-- CHECKBOX -->
					<div class="checkboxContainer">
						<InputCheckbox v-model="doNotShowAgain">
							<span class="checkboxLabel">{{ t("common.doNotShowAgain") }}</span>
						</InputCheckbox>
					</div>
					<!-- CHECKBOX -->

					<!-- CLOSE BUTTON -->
					<div class="announcementButtons">
						<button class="mainButton closeButton" @click="onCloseAnnouncement">
							{{ t("common.close") }}
						</button>
					</div>
					<!-- CLOSE BUTTON -->
				</div>
			</div>
		</div>
		<!-- ================= ANNOUNCEMENT ================= -->
	</Transition>

	<Transition name="fade" mode="out-in" :duration="500">
		<!-- ================= ANNOUNCEMENT POPUP ================= -->
		<div class="overlay" v-if="showAnnouncementPopUp && isHomePage && isLogin" :key="popUpIndex">
			<div class="announcementDialog">
				<div class="announcementContent noScrollBar">
					<!-- BANNER -->
					<div class="announcementImageContainer" v-if="sortedAnnouncementPopUpItem[popUpIndex].popUpImage">
						<BaseImage
							:imgSrc="
								getImageSrc(
									filteredAnnouncementPopUpItem[popUpIndex].popUpImage,
									filteredAnnouncementPopUpItem[popUpIndex].popUpType
								)
							"
							:isFullUrl="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 2"
							class="announcementImage"
							decoding="sync"
							@click.self="
								() => {
									if (filteredAnnouncementPopUpItem[popUpIndex].popUpType == 1)
										handleClaimBonus(filteredAnnouncementPopUpItem[popUpIndex].actionId);
									if (filteredAnnouncementPopUpItem[popUpIndex].popUpType == 3) {
										onCloseAnnouncementPopUp(popUpIndex);
										redirectCheckIn();
									}
								}
							"
						/>

						<div v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 1" class="insetButtons">
							<button class="doneButton secondaryButton" @click.stop="onCloseAnnouncementPopUp(popUpIndex)">
								{{ t("common.close") }}
							</button>
							<button
								class="claimNowButton mainButton"
								v-if="filteredAnnouncementPopUpItem[popUpIndex]?.extra.toUpperCase() == 'NEW MEMBER'"
								@click.stop="handleClaimBonus(filteredAnnouncementPopUpItem[popUpIndex].actionId)"
							>
								{{ t("common.claimNow") }}
							</button>

							<button
								class="claimNowButton mainButton"
								v-else-if="filteredAnnouncementPopUpItem[popUpIndex]?.extra.toUpperCase() == 'REFERRAL'"
								@click.stop="
									onCloseAnnouncementPopUp(popUpIndex);
									redirectReferral();
								"
							>
								{{ t("common.referralNow") }}
							</button>
						</div>

						<div v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 3" class="insetButtons">
							<button class="doneButton secondaryButton" @click.stop="onCloseAnnouncementPopUp(popUpIndex)">
								{{ t("common.close") }}
							</button>
							<button
								class="claimNowButton mainButton"
								@click.stop="
									() => {
										onCloseAnnouncementPopUp(popUpIndex);
										redirectCheckIn();
									}
								"
							>
								{{ t("common.checkIn") }}
							</button>
						</div>
					</div>
					<!-- BANNER -->

					<template v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 2">
						<!-- ANNOUNCEMENT TEXT -->
						<div
							class="announcementTitle"
							v-if="filteredAnnouncementPopUpItem[popUpIndex].title"
							v-html="filteredAnnouncementPopUpItem[popUpIndex].title"
						></div>
						<div
							v-if="filteredAnnouncementPopUpItem[popUpIndex].content"
							v-html="filteredAnnouncementPopUpItem[popUpIndex].content"
							class="announcementTextContent"
						></div>
						<!-- ANNOUNCEMENT TEXT -->

						<!-- CHECKBOX -->
						<div class="checkboxContainer">
							<InputCheckbox v-model="doNotShowAgain">
								<span class="checkboxLabel">{{ t("common.doNotShowAgain") }}</span>
							</InputCheckbox>
						</div>
						<!-- CHECKBOX -->

						<!-- CLOSE BUTTON -->
						<div class="announcementButtons">
							<button class="mainButton closeButton" @click="onCloseAnnouncementPopUp">
								{{ t("common.close") }}
							</button>
						</div>
						<!-- CLOSE BUTTON -->
					</template>
				</div>
			</div>
		</div>
		<!-- ================= ANNOUNCEMENT POPUP ================= -->
	</Transition>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError, toastOptionInfo } from "@/composables/useToastOptions";
import { useLoading } from "vue-loading-overlay";
import { useCookies } from "vue3-cookies";
import { routeData } from "@/constant";
import { isLogin } from "@/composables/useAuth";
import { languageCodeToId } from "@/composables/useCulture";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import InputCheckbox from "@/components/dynamic/Inputs/InputCheckbox.vue";

const store = useStore();
const { t, locale } = useI18n();
const { cookies } = useCookies();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const $loading = useLoading();

const checkIn = routeData.find((route) => route.title == "checkin");

const qrcode = routeData.find((item) => item.title == "qrcode");

const scan = routeData.find((item) => item.title == "scan");

const isHomePage = computed(() => route.path == "/");

// ================= Announcement Start =================

const announcementItem = computed(() => store.getters["announcement/getAnnouncement"]);
const languageFilteredAnnouncement = computed(() =>
	announcementItem.value.filter((announcement) => announcement.langId == languageCodeToId[locale.value])
);
const hideAnnouncement = ref(false);
const showAnnouncement = ref(false);

// ================= Announcement End =================

// ================= Announcement Popup Start =================

const announcementPopUpItem = computed(
	() => store.getters["announcement/getAnnouncementPopUp"]
	// [
	// 	{
	// 		id: "40aeda71-2e4c-4a95-b047-f8b0c6071d7a",
	// 		title: "<p>Start deposit and enjoy your game~ good lucks! heng ah~ ong ah~ huat ah!</p>\r\n",
	// 		content: "",
	// 		popUpType: 2,
	// 		popUpImage:
	// 			"https://s3-ap-southeast-1.amazonaws.com/cdn.vava88.club/Announcement/cc41d06f-a6a7-44c7-bc5c-3eea0ca196edwng-welcome-after.jpg",
	// 		langId: 1,
	// 		sequence: 3,
	// 		actionId: 188,
	// 		actionType: false,
	// 	},
	// ]
);
const sortedAnnouncementPopUpItem = computed(() =>
	announcementPopUpItem.value.toSorted((a, b) => {
		return a.sequence - b.sequence;
	})
);
const filteredAnnouncementPopUpItem = computed(() =>
	sortedAnnouncementPopUpItem.value.filter((item) => !hidePopUpTypes.value[item.popUpType])
);

const popUpIndex = ref(0);
const hidePopUpTypes = ref(cookies.get("hidePopUpTypes") || {});
const showAnnouncementPopUp = computed(
	() => filteredAnnouncementPopUpItem.value.length && popUpIndex.value < filteredAnnouncementPopUpItem.value.length
);

const getImageSrc = (imgSrc, type) => {
	if (type == 1) return "Bonus/BannerImage/" + imgSrc;
	if (type == 2) return imgSrc;
	if (type == 3) return "Event/PopUpImage/" + imgSrc;
};

const isLoginEventStart = computed(() => isLogin.value && store.getters["events/getMemberLoginEvent"]);
const redirectCheckIn = () => {
	if (isLoginEventStart.value) router.push(checkIn.link);
	else toast.info(t("toast.checkInNotStartYet"), toastOptionInfo);
};

const redirectReferral = () => {
	router.push(qrcode.link);
};

// ================= Announcement Popup End =================

// ================= Open/Close Start =================

const doNotShowAgain = ref(false);

const initAnnouncement = () => {
	hideAnnouncement.value = cookies.get("hideAnnouncement") == "true" || false;
	if (
		languageFilteredAnnouncement.value.length &&
		languageFilteredAnnouncement.value[0].status &&
		!hideAnnouncement.value
	)
		showAnnouncement.value = true;
};
const onCloseAnnouncement = () => {
	let midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	cookies.set("hideAnnouncement", doNotShowAgain.value, midnight);
	doNotShowAgain.value = false;
	showAnnouncement.value = false;
};

const initPopUp = () => {
	hidePopUpTypes.value = cookies.get("hidePopUpTypes") || {};
	popUpIndex.value = 0;
};
const onCloseAnnouncementPopUp = () => {
	let midnight = new Date();
	midnight.setHours(23, 59, 59, 0);

	const popUpType = filteredAnnouncementPopUpItem.value[popUpIndex.value].popUpType;
	let newCookies = cookies.get("hidePopUpTypes");
	newCookies = { ...newCookies };
	newCookies[popUpType] = doNotShowAgain.value;
	cookies.set("hidePopUpTypes", newCookies, midnight);

	doNotShowAgain.value = false;
	popUpIndex.value += 1;
};

// ================= Open/Close End =================

// ================= Fetch Start =================

const fetchAnnouncement = async () => {
	if (isLogin.value) {
		await store.dispatch("announcement/fetchAnnouncementPopUp", { langId: languageCodeToId[locale.value] });
		initPopUp();
	} else {
		await store.dispatch("announcement/fetchAnnouncement", "BeforeLogin");
		initAnnouncement();
	}
};

const refreshStatus = computed(() => {
	return store.getters["identityServer/getOnRefresh"];
});
watch(refreshStatus, (isRefresh) => {
	if (!isRefresh) fetchAnnouncement();
});
watch(isLogin, (newLoginStatus) => {
	fetchAnnouncement();
});

// ================= Fetch End =================

// ================= Submit Start =================

const handleClaimBonus = async (actionId) => {
	const loader = $loading.show();

	try {
		// const claimBonusRes = await store.dispatch("bonus/claimBonus", { bonusID: actionId });
		// if (!claimBonusRes?.succeeded || !claimBonusRes?.bonusId) {
		// 	toast.error(t("toast.claimUnsuccessful", { error: claimBonusRes?.error }), toastOptionError);
		// 	return false;
		// }
		// const claimBonusIdRes = await store.dispatch("bonus/claimBonusId", {
		// 	bonusClaimId: claimBonusRes?.bonusId,
		// 	bonusId: actionId,
		// });

		const directClaimRes = await store.dispatch("bonus/directClaim", { bonusId: actionId });
		if (!directClaimRes?.succeeded || !directClaimRes?.bonusId) {
			toast.error(t("toast.claimUnsuccessful", { error: directClaimRes?.error }), toastOptionError);
			return false;
		} else {
			toast.success(t("toast.claimSuccessful"), toastOptionSuccess);
			emitBonusClaimed();
			await store.dispatch("memberAccount/fetchMemberWallet");
			// await store.dispatch("announcement/fetchAnnouncementPopUp", { langId: languageCodeToId[locale.value] });
		}
	} catch (err) {
		toast.error(t("toast.claimUnsuccessful", { error: err.response?.data.title }), toastOptionError);
	} finally {
		loader.hide();
		onCloseAnnouncementPopUp();
	}
};

const emitBonusClaimed = () => {
	// broadcast signal for other tabs to close announcement
	localStorage.setItem("bonusClaimed", Date.now().toString());
};
const watchBonusClaimed = () => {
	window.addEventListener("storage", (event) => {
		// close bonus announcement if claimed on another tab
		if (event.key === "bonusClaimed" && filteredAnnouncementPopUpItem.value[popUpIndex.value].popUpType == 1) {
			onCloseAnnouncementPopUp();
		}
	});
};

// ================= Submit End =================

onMounted(() => {
	watchBonusClaimed();
});
</script>

<style scoped lang="sass">
.overlay
	z-index: 1000
	overflow: auto
.announcementDialog
	position: relative
	display: flex
	flex-direction: column
	justify-content: center
	width: auto
	height: 100%
	color: var(--overlay-text)
	pointer-events: none
.announcementContent
	display: flex
	flex-direction: column
	justify-content: center
	gap: 1rem
	position: relative
	width: 100%
	height: 100%
	padding: 1rem
	pointer-events: auto
	z-index: 1001
.announcementImageContainer
	position: relative
	overflow: hidden
	width: fit-content
	margin: 0 auto
.announcementImage
	max-width: 100%
	max-height: 100%
	display: block
	width: auto !important
	border-radius: 10px
	margin: 0 auto
.insetButtons
	position: absolute
	bottom: 3rem
	padding-inline: 0.5rem
	display: flex
	justify-content: center
	gap: 1rem
	width: 100%
.claimNowButton, .doneButton
	width: 100%
	max-width: 10rem
	padding: 0.7rem
	box-shadow: 0 0 6px 0 #00000033
.doneButton
	outline: none
	border: none
	background: var(--background-primary)
.announcementTitle
	text-align: center
	font-weight: var(--font-medium)
	:deep(p)
		margin: 0
.announcementTextContent
	text-align: center
.checkboxContainer
	display: flex
	justify-content: center
.announcementButtons
	display: flex
	justify-content: center
	width: 100%
.closeButton
	margin-inline: auto
</style>
