import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { EventStateInterface } from "./state";
import { GetEventResponse } from "@/types/Event/Response/GetEventResponse";
import { MemberLoginEventResponse } from "@/types/Event/Response/MemberLoginEventResponse";
import { MemberRecheckInResponse } from "@/types/Event/Response/MemberRecheckInResponse";
import { GetMemberEventReportResponse } from "@/types/Event/Response/GetMemberEventReportResponse";
import { AddOnBonusResponse } from "@/types/Event/Response/AddOnBonusResponse";
import { GetUserDepositAmountResponse } from "@/types/Payment/Response/GetUserDepositAmountResponse";

export const getters: GetterTree<EventStateInterface, RootState> = {
	getEventList(state) {
		return state.eventList;
	},
	getMemberLoginEvent(state): MemberLoginEventResponse {
		return state.memberLoginEvent;
	},
	getMemberRecheckIn(state) {
		return state.memberRecheckInList;
	},
	getMemberEvent(state) {
		return state.memberEventReport;
	},
	getEventRewardByDay(state) {
		return state.eventRewardByDay;
	},
	getEventClaimedByDay(state) {
		return state.eventClaimedByDay;
	},
	getMemberRecheckInByDay(state) {
		return state.memberRecheckInByDay;
	},
	getMemberAddOnBonus(state): AddOnBonusResponse {
		return state.memberAddOnBonus;
	},
	getUserDepositAmount(state): GetUserDepositAmountResponse[] {
		return state.userDepositAmount;
	},
};
