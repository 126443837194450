<template>
	<div class="mobileDockContainer">
		<div class="dockList">
			<template v-for="nav in filteredDockList" :key="nav.title">
				<UnifiedLink
					:isRouteLink="nav.isRouteLink"
					:link="nav.link"
					:isModal="nav.isModal"
					:modalName="nav.modalName"
					:isEvent="nav.isEvent"
					:validateLogin="nav.validateLogin"
					:validateBank="nav.validateBank"
					@click="handleEvent(nav.event)"
				>
					<div class="dockItem" :class="{ main: nav.title == MAIN_NAV, active: route.name == nav.title }">
						<div class="dockItemIconContainer">
							<font-awesome-icon class="dockItemIcon" :icon="nav.icon" v-if="nav.title != MAIN_NAV" />
							<div v-if="nav.notification > 0" class="dockItemNotification">
								{{ nav.notification }}
							</div>
						</div>
						<!-- <base-image v-else :imgSrc="logo" class="dockLogo"></base-image> -->
						<div v-if="nav.title != MAIN_NAV" class="dockItemText">
							{{ t(`route.${nav.title}`) }}
						</div>
					</div>
				</UnifiedLink>
			</template>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { routeData } from "@/constant";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import store from "@/store/index";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import { useRouter, useRoute } from "vue-router";
import { toastOptionSuccess, toastOptionError, toastOptionInfo } from "@/composables/useToastOptions";
import { isLogin } from "@/composables/useAuth";
import { currentTheme } from "@/composables/useDarkTheme";

const { t, locale } = useI18n();
const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const logo = computed(() =>
	currentTheme.value == "light"
		? "assets/images/NavBar/WinnGo_rounded.png"
		: "assets/images/NavBar/WinnGo_rounded_dark.png"
);
const logoUrl = computed(() => `url(${imgUrlDomain + logo.value})`);
const router = useRouter();
const route = useRoute();
const toast = useToast();

const liveChat = routeData.find((item) => item.title == "livechat");
const checkIn = routeData.find((item) => item.title == "checkin");

// ================= List Start =================

// !!! Check if name exists in constant.ts !!!
const dockList = ["inbox", "vip", "home", "statement", "myaccount"];
const contactPage = routeData.find((x) => x.title == "contact");
const MAIN_NAV = "home";

// Additional data
const getUnreadInboxCount = computed(() => store.getters["inbox/getInboxUnreadCount"]);

const notification = computed(() => {
	return { inbox: getUnreadInboxCount.value };
});

const filteredDockList = computed(() => {
	return dockList.reduce((newList, title) => {
		newList.push({
			...routeData.find((nav) => {
				return nav.title == title;
			}),
			notification: notification.value?.[title],
		});
		return newList;
	}, []);
});

const handleEvent = (event) => {
	if (event == liveChat.event) openLivechat();
	if (event == checkIn.event) goToCheckIn();
	return;
};

const contactList = computed(() => store.getters["brand/getBrand"]);
const openLivechat = () => {
	const livechatItem = contactList.value.find((x) => x?.brandName.toLowerCase() == "livechat");
	if (livechatItem != undefined) {
		let newWin = window.open(livechatItem?.redirectURL, "_blank");
		if (!newWin || newWin.closed || newWin.closed == "undefined") {
			toast.error(t("toast.popUpBlocked"), toastOptionError);
		}
		// window.location.href = livechatItem.redirectURL;
	} else {
		router.push(contactPage.link);
	}
};

const isLoginEventStart = computed(() => store.getters["events/getMemberLoginEvent"]);
const goToCheckIn = () => {
	if (isLoginEventStart.value) router.push(checkIn.link);
	else toast.info(t("toast.checkInNotStartYet"), toastOptionInfo);
};

// ================= List End =================
</script>

<style scoped lang="sass">
.mobileDockContainer
	display: block
	position: fixed
	bottom: 0
	height: var(--mobile-dock-height)
	width: 100%
	z-index: 30
	background: var(--background-primary)
	box-shadow: var(--box-shadow)
	margin: 0 auto
	@media (max-width: 767px)
		padding-top: 0.5rem
@media (min-width: 1201px)
	.mobileDockContainer
		bottom: var(--phone-border-width)
		max-width: calc(var(--screen-max-width) - 2*var(--phone-border-width))
		border-radius: 0 0 calc(var(--phone-border-radius) - var(--phone-border-width)) calc(var(--phone-border-radius) - var(--phone-border-width))

.dockList
	display: flex
	justify-content: space-evenly
	align-items: center
	height: 100%
	width: 100%
	@media (max-width: 767px)
		align-items: start
.dockItem
	display: flex
	flex-direction: column
	align-items: center
	width: 4rem
	gap: 0.5rem
	@media (max-width: 767px)
		gap: 0.3rem
	&.main
		width: 4rem
		height: 4rem
		display: flex
		justify-content: center
		align-items: center
		transform: translate(0, -30%)
		border-radius: 50%
		background: var(--color-accent)
		background-image: v-bind('logoUrl')
		background-size: 4rem 4rem
		color: var(--text-regular-inverted)
		box-shadow: var(--box-shadow)
		@media (max-width: 767px)
			transform: translate(0, -40%)
		.dockItemIcon
			font-size: 2em
	.dockItemIconContainer
		position: relative
		.dockItemNotification
			position: absolute
			top: -0.25rem
			right: -0.5rem
			line-height: 1em
			font-size: 0.75em
			font-weight: var(--font-bold)
			text-align: center
			color: var(--text-regular-inverted)
			background: var(--danger)
			min-width: 1.5em
			border-radius: 999rem
			outline: 1.5px solid var(--background-primary)
			padding: 0.25em 0.25em

:deep(.router-link-active .dockItem:not(.main)), .dockItem:not(.main).active
	color: var(--text-accent)
.dockItemIcon
	font-size: 1.5em
	@media (max-width: 767px)
		font-size: 1.3em
.dockItemText
	font-weight: var(--font-medium)
	text-align: center
	line-height: 0.9em
	font-size: 0.65rem

.dockLogo
	border-radius: 50%
</style>
