import { ActionTree } from "vuex";
import bonusService from "@/network/BonusService/Bonus";
import { BonusStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BonusMutations } from "./mutations";
import { clientDateStringToServerISOString } from "@/composables/useCountry";

export const actions: ActionTree<BonusStateInterface, RootState> = {
	async fetchBonusList(context) {
		if (context.state.bonusList.length > 0) {
			return;
		}
		const res = await bonusService.getBonusList();
		context.commit(BonusMutations.SET_BONUS_ALL_LIST, res);
		context.commit(BonusMutations.SET_BONUS_LIST, res);
	},
	async fetchMemberBonusList(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusByMemberGroup(memberId);
		context.commit(BonusMutations.SET_MEMBER_BONUS, res);
	},
	async fetchMemberPreBonusList(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getMemberPreBonus(memberId, payload.langId);
		context.commit(BonusMutations.SET_MEMBER_PRE_BONUS, res);
	},
	async fetchUniqueCode(context) {
		const res = await bonusService.getUniqueCode();
		context.commit(BonusMutations.SET_MEMBER_BONUS, res);
	},
	async fetchUniqueCodeStatus(context, payload) {
		const res = await bonusService.getUniqueCodeClaimedStatus(payload.loginId, payload.uniqueCodeId);
		return res;
	},
	async fetchBonusClaim(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusClaimed(
			memberId,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true),
			payload.lang
		);
		context.commit(BonusMutations.SET_BONUS_CLAIMED, res);
	},
	async fetchActiveBonusApplied(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getActiveBonusApplied(memberId);
		const bonusList = context.rootGetters["bonus/getBonusList"];

		context.commit(BonusMutations.SET_ACTIVE_BONUS_APPLIED, res);
	},
	async claimBonus(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		return await bonusService.claimBonus(memberId, payload.bonusID);
	},
	async claimBonusId(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await bonusService.claimBonusId(memberId, payload.bonusClaimId, loginId, payload.bonusId);
	},
	async directClaim(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await bonusService.directClaim(memberId, loginId, payload.bonusId);
	},
	async claimMemberReferralBonus(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		return await bonusService.claimMemberReferralBonus(memberId, payload.referralBonusId);
	},
	async fetchPromotionBonus(context, payload) {
		const memberCurrentTier = context.rootGetters["memberGroup/getMemberCurrentTier"]?.currentTier?.tier;
		const res = await bonusService.getPromotionBonus(payload.languageId, memberCurrentTier);
		context.commit(BonusMutations.SET_PROMOTION_BONUS, res);
	},
};
