import { ActionTree } from "vuex";
import { getDownloadLinks } from "@/network/DownloadLinksService/DownloadLinks";
import { DownloadLinksStateInterface } from "./state";
import { RootState } from "@/store/state";
import { DownloadLinksMutations } from "./mutations";

export const actions: ActionTree<DownloadLinksStateInterface, RootState> = {
	async fetchDownloadLinks(context, payload) {
		// const vendorId = context.rootGetters["vendors/getVendor"].id;
		const res = await getDownloadLinks(payload.vendorId, payload.downloadType);
		context.commit(DownloadLinksMutations.SET_DOWNLOAD_LINKS, res);
	},
};
