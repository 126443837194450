import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { DownloadLinksStateInterface } from "./state";
import { DownloadLinksResponse } from "@/types/DownloadLinks/Response/DownloadLinksResponse";

export const getters: GetterTree<DownloadLinksStateInterface, RootState> = {
	getDownloadLinks(state) {
		return (downloadType: string, vendorId: number): DownloadLinksResponse => {
			const downloadLink = state.downloadLinks.find(
				(x) => x.downloadType == downloadType && x.vendorId == vendorId
			) as DownloadLinksResponse;
			return downloadLink;
		};
	},
};
