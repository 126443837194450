import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { PaymentStateInterface } from "./state";
import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";
import { BonusBirthdayResponse } from "@/types/Payment/Response/BonusBirthdayResponse";
import { serverISOStringToDateGMT, clientTimeNow } from "@/composables/useCountry";

// const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<PaymentStateInterface, RootState> = {
	getMemberDepositStatement(state): StatementResponse[] {
		state.depositStatement.map((depositItem) => {
			const createDate = serverISOStringToDateGMT(depositItem.createdDate);
			depositItem.createdDate = createDate;
			return depositItem;
		});
		state.depositStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.depositStatement;
	},

	getMemberWithdrawalStatement(state): StatementResponse[] {
		state.withdrawalStatement.map((withdrawalItem) => {
			const createDate = serverISOStringToDateGMT(withdrawalItem.createdDate);
			withdrawalItem.createdDate = createDate;
			return withdrawalItem;
		});
		state.withdrawalStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.withdrawalStatement;
	},

	getMemberTransferStatement(state, _, rootState, rootGetters): StatementResponse[] {
		state.transferStatement.map((transferItem) => {
			const createDate = serverISOStringToDateGMT(transferItem.createdDate);
			transferItem.createdDate = createDate;
			return transferItem;
		});

		state.transferStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.transferStatement;
	},

	getMemberAdjustment(state): StatementResponse[] {
		state.adjustment.map((adjustmentItem) => {
			const createDate = serverISOStringToDateGMT(adjustmentItem.createdDate);
			adjustmentItem.createdDate = createDate;
			return adjustmentItem;
		});
		state.adjustment.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.adjustment;
	},

	getMemberBonusReferral(state): BonusReferralResponse[] {
		return state.bonusReferral;
	},

	getMemberBonusBirthday(state): BonusBirthdayResponse {
		return state.bonusBirthday;
	},

	getMemberInterestStatement(state): StatementResponse[] {
		state.interestStatement.map((interestItem) => {
			const createDate = serverISOStringToDateGMT(interestItem.createdDate);
			interestItem.createdDate = createDate;
			return interestItem;
		});
		state.interestStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.interestStatement;
	},
};
