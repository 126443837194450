import { ref, computed, watch, onMounted } from "vue";
import { isLogin } from "@/composables/useAuth";
import store from "@/store/index";
import router from "@/router";
import vueI18n from "@/resource/index";
import { languageCodeToId } from "@/composables/useCulture";

const { t, locale } = vueI18n.global;
// ================= Fetch Start =================

const queueFetch = () => store.dispatch("progressTracker/queueTask");
const completeFetch = () => store.dispatch("progressTracker/completeTask");

const fetchWinover = async () => {
	queueFetch();
	const loginId = store.getters["identityServer/getLoginId"];
	await store
		.dispatch("memberAccount/fetchMemberWinover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
};

const fetchTurnover = async () => {
	queueFetch();
	const loginId = store.getters["identityServer/getLoginId"];
	await store
		.dispatch("memberAccount/fetchMemberTurnover", {
			loginId: loginId,
			isFromDB: true,
		})
		.finally(() => completeFetch());
};
const fetchMemberActiveVendorList = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchMemberActiveVendorList").finally(() => completeFetch());
};

const fetchMember = async () => {
	queueFetch();
	await store.dispatch("member/fetchMember").finally(() => completeFetch());
};

const fetchMemberWallet = async () => {
	queueFetch();
	await store.dispatch("memberAccount/fetchMemberWallet").finally(() => completeFetch());
};

const fetchMemberTier = async () => {
	queueFetch();
	await store.dispatch("memberGroup/fetchMemberCurrentTier").finally(() => completeFetch());
};

const fetchMemberGroupList = async () => {
	queueFetch();
	await store.dispatch("memberGroup/fetchMemberGroupList").finally(() => completeFetch());
};

const fetchVendorCategory = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchCategoryList").finally(() => completeFetch());
};

const fetchVendors = async () => {
	queueFetch();
	await store.dispatch("vendors/fetchVendor").finally(() => completeFetch());
};

const tryLogin = async () => {
	queueFetch();
	await store.dispatch("identityServer/tryLogin").finally(() => completeFetch());
};

const fetchIPAddress = async () => {
	queueFetch();
	await store.dispatch("ipAddress/fetchIPAddress").finally(() => completeFetch());
};

const fetchMemberBonusList = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchMemberBonusList").finally(() => completeFetch());
};

const fetchBonusList = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchBonusList").finally(() => completeFetch());
};

const fetchRebate = async () => {
	queueFetch();
	await store.dispatch("tierRebate/fetchRebateTier").finally(() => completeFetch());
};

const fetchActiveBonusApplied = async () => {
	queueFetch();
	await store.dispatch("bonus/fetchActiveBonusApplied").finally(() => completeFetch());
};

const fetchGames = async () => {
	queueFetch();
	await store.dispatch("games/fetchGameList", { id: null }).finally(() => completeFetch());
};

const fetchBanner = async () => {
	queueFetch();
	await store.dispatch("banner/fetchBanner").finally(() => completeFetch());
};

const fetchMemberBank = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberBank").finally(() => completeFetch());
};

const fetchBrand = async () => {
	queueFetch();
	await store.dispatch("brand/fetchBrand").finally(() => completeFetch());
};

const fetchDepositBank = async () => {
	queueFetch();
	await store.dispatch("bank/fetchDepositBank").finally(() => completeFetch());
};

const fetchGlobalSetting = async () => {
	queueFetch();
	await store.dispatch("globalSetting/fetchGlobalSetting", { remark: "" }).finally(() => completeFetch());
};

const fetchPromotion = async () => {
	queueFetch();
	await store.dispatch("payment/fetchMemberBonusBirthday").finally(() => completeFetch());
};

const fetchCheckInList = async () => {
	queueFetch();
	await store.dispatch("events/fetchMemberLoginEvent").finally(() => completeFetch());
};

const fetchMemberReferral = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberReferral").finally(() => completeFetch());
};

const fetchMemberReferralList = async () => {
	queueFetch();
	await store.dispatch("member/fetchMemberReferralList").finally(() => completeFetch());
};

const fetchInboxList = async () => {
	queueFetch();
	await store
		.dispatch("inbox/fetchMemberInbox", { languageId: languageCodeToId[locale.value] })
		.finally(() => completeFetch());
};

const fetchMiniGames = async () => {
	queueFetch();
	await store.dispatch("miniGames/fetchMiniGames").finally(() => completeFetch());
};

const fetchMiniGamesUser = async () => {
	queueFetch();
	await store.dispatch("miniGames/fetchMiniGamesUser").finally(() => completeFetch());
};

const fetchInterestSetting = async () => {
	queueFetch();
	await store.dispatch("interest/fetchInterestSetting").finally(() => completeFetch());
};

const fetchMemberAddOnBonus = async () => {
	queueFetch();
	await store.dispatch("events/fetchMemberAddOnBonus").finally(() => completeFetch());
};

const fetchInterestPayoutHistory = async () => {
	queueFetch();

	const DATE_RANGE = 30;

	const today = new Date();
	const priorDate = new Date(new Date().setDate(today.getDate() - DATE_RANGE));

	const dateTo = today.toLocaleString("en-CA").slice(0, 10);
	const dateFrom = priorDate.toLocaleString("en-CA").slice(0, 10);

	await store
		.dispatch("interest/fetchInterestPayoutHistory", { dateFrom: dateFrom, dateTo: dateTo })
		.finally(() => completeFetch());
};

const fetchTimezone = async () => {
	queueFetch();
	await store.dispatch("country/fetchTimezone").finally(() => completeFetch());
};

const fetchPromotionBonus = async () => {
	queueFetch();
	await store
		.dispatch("bonus/fetchPromotionBonus", { languageId: languageCodeToId[locale.value] })
		.finally(() => completeFetch());
};

// ================= Fetch End =================

// ================= Change Locale Fetch Start =================
watch(locale, async () => {
	fetchPromotionBonus();
	if (isLogin) {
		fetchInboxList();
	}
});
// ================= Change Locale Fetch End =================

const fetchBeforeLoginList = async () => {
	fetchIPAddress();
	fetchTimezone();
	fetchVendors();
	// fetchBonusList();
	fetchGames();
	fetchBanner();
	fetchBrand();
	fetchVendorCategory();
	fetchMiniGames();
	fetchRebate();
	fetchPromotionBonus();
};

const fetchAfterLoginList = async () => {
	// Can run in parallel
	fetchWinover();
	fetchTurnover();
	fetchGlobalSetting();
	fetchDepositBank();
	fetchMemberReferral();
	//fetchPromotion();
	fetchMemberBank();
	fetchMemberGroupList();

	// Has dependency on member data
	await fetchMember();
	fetchMemberWallet();
	await fetchMemberTier();
	fetchMemberActiveVendorList();
	fetchCheckInList();
	fetchMemberBonusList();
	fetchMemberReferralList();
	fetchActiveBonusApplied();
	fetchInboxList();
	fetchMiniGames();
	fetchMiniGamesUser();
	fetchInterestSetting();
	fetchMemberAddOnBonus();
	fetchInterestPayoutHistory();
	fetchPromotionBonus();
};

const fetchAPIList = () => {
	if (isLogin) fetchAfterLoginList();
	else fetchBeforeLoginList();
};
export { fetchBeforeLoginList, fetchAfterLoginList, fetchAPIList };
