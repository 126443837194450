/* eslint-disable no-console */

import { register } from "register-service-worker";
import { useToast } from "vue-toastification";
import vueI18n from "@/resource/index";
const { t, locale } = vueI18n.global;

const toast = useToast();

const fullRefresh = () => {
	window.location.reload();
};

if (process.env.NODE_ENV === "production") {
	// ========================================
	// Only register service worker in new domain
	// Added 19/09/2024, remove code between separator in future when appropriate
	if (window.location.hostname.includes("gogonwin.site"))
		// ========================================
		register(`${process.env.BASE_URL}service-worker.js`, {
			ready() {
				console.log(
					"App is being served from cache by a service worker.\n" +
						"For more details, visit https://goo.gl/AFskqB"
				);
			},
			registered() {
				console.log("Service worker has been registered.");
			},
			cached() {
				console.log("Content has been cached for offline use.");
			},
			updatefound() {
				console.log("New content is downloading.");
			},
			updated() {
				console.log("New content is available; please refresh.");
				toast.info(() => t("toast.newVersion"), {
					timeout: false,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false,
					onClick: () => fullRefresh(),
				});
			},
			offline() {
				console.log("No internet connection found. App is running in offline mode.");
			},
			error(error) {
				console.error("Error during service worker registration:", error);
			},
		});
}
