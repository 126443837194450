import { ActionTree } from "vuex";
import { getRunningText, getAnnouncement, getAnnouncementPopUp } from "@/network/AnnouncementService/Announcement";
import { AnnouncementStateInterface } from "./state";
import { RootState } from "@/store/state";
import { AnnouncementMutations } from "./mutations";

export const actions: ActionTree<AnnouncementStateInterface, RootState> = {
	async fetchRunningText(context) {
		if (context.state.runningText.length > 0) {
			return;
		}
		const res = await getRunningText("All", "All");
		context.commit(AnnouncementMutations.SET_RUNNING_TEXT, res);
	},
	async fetchAnnouncement(context, payload) {
		const res = await getAnnouncement(payload);
		context.commit(AnnouncementMutations.SET_ANNOUNCEMENT, res);
	},
	async fetchAnnouncementPopUp(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await getAnnouncementPopUp(memberId, payload.langId);
		context.commit(AnnouncementMutations.SET_ANNOUNCEMENT_POPUP, res);
	},
};
