import httpClient, { ClientId } from "./HttpClient";
import { AutoDepositRequest } from "@/types/PaymentChannel/Request/AutoDepositRequest";
import { AutoDepositResponse } from "@/types/PaymentChannel/Response/AutoDepositResponse";

const addAutoDepositTicket = (
	bankCode: string,
	amount: number,
	bonusId: number,
	loginId: string,
	paymentGatewayMethodId: number,
	ipAddress: string,
	method: string,
	clientPaymentGatewayId: number
): Promise<AutoDepositResponse> => {
	console.log(method);
	const endPoint = `/api/AutoPayment/` + method;
	const request: AutoDepositRequest = {
		loginId: loginId,
		bankCode: bankCode,
		ip: ipAddress,
		amount: amount,
		paymentGatewayMethodId: paymentGatewayMethodId,
		failurl: location.origin + "/statement",
		successurl: location.origin + "/statement",
		isMW: false,
		bonusId: bonusId,
		clientPaymentGatewayId: clientPaymentGatewayId,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

export default {
	addAutoDepositTicket,
};
