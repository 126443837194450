import { ref, watch, nextTick } from "vue";
import { usePreferredColorScheme } from "@vueuse/core";

// themes: 'light', 'dark', 'system'

const html = document.querySelector("html");
const meta = document.querySelector('meta[name="theme-color"]');

const DEFAULT_THEME = "system";

const LIGHT_THEME_COLOR = "#029450";
const DARK_THEME_COLOR = "#161616";

const currentTheme = ref("");

// ================= System Start =================

const deviceThemeColor = usePreferredColorScheme();
const syncDeviceTheme = () => {
	if (deviceThemeColor.value == "light") {
		html.setAttribute("data-theme", "light");
		meta.setAttribute("content", LIGHT_THEME_COLOR);
		currentTheme.value = "light";
	}
	if (deviceThemeColor.value == "dark") {
		html.setAttribute("data-theme", "dark");
		meta.setAttribute("content", DARK_THEME_COLOR);
		currentTheme.value = "dark";
	}
};
watch(deviceThemeColor, async () => {
	await nextTick();
	if (localStorage.getItem("theme") == "system") syncDeviceTheme();
});

// ================= System End =================

// ================= Manual Start =================

const themePreference = ref("");

const setThemePreference = (theme) => {
	if (theme == "light") {
		html.setAttribute("data-theme", "light");
		meta.setAttribute("content", LIGHT_THEME_COLOR);
		localStorage.setItem("theme", "light");
		themePreference.value = "light";
		currentTheme.value = "light";
	}
	if (theme == "dark") {
		html.setAttribute("data-theme", "dark");
		meta.setAttribute("content", DARK_THEME_COLOR);
		localStorage.setItem("theme", "dark");
		themePreference.value = "dark";
		currentTheme.value = "dark";
	}
	if (theme == "system") {
		syncDeviceTheme();
		localStorage.setItem("theme", "system");
		themePreference.value = "system";
	}
};

setThemePreference(localStorage.getItem("theme") || DEFAULT_THEME);

// ================= Manual End =================

export { setThemePreference, themePreference, currentTheme };
