import { RebateGroupStateInterface } from "./state";
import { MutationTree } from "vuex";
import {
    TierSettingGroupByTierResponse,
    RebateJobSettingMemberGroup,
} from "@/types/Rebate/Response/RebateResponse";

export enum RebateTierMutations {
  SET_REBATE_TIER = "SET_REBATE_TIER",
  SET_MEMBER_GROUP_REBATE = "SET_MEMBER_GROUP_REBATE",
}

export const mutations: MutationTree<RebateGroupStateInterface> = {
  [RebateTierMutations.SET_REBATE_TIER](
    state,
    payload: TierSettingGroupByTierResponse[]
  ) {
    state.tierGroup = payload;
  },
  [RebateTierMutations.SET_MEMBER_GROUP_REBATE](
    state,
    payload: RebateJobSettingMemberGroup[]
  ) {
    state.memberGroupRebate = payload;
  },

};
