import { ActionTree } from "vuex";
import paymentService from "@/network/PaymentService/Payment";
import { PaymentStateInterface } from "./state";
import { RootState } from "@/store/state";
import { PaymentMutations } from "./mutations";
import { TransferErrorResponse } from "@/types/Payment/Response/TransferErrorResponse";
import { clientDateStringToServerISOString } from "@/composables/useCountry";

export const actions: ActionTree<PaymentStateInterface, RootState> = {
	async fetchMemberStatement(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getStatement(
			loginId,
			payload.type,
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true)
		);
		if (payload.type == "Deposit") {
			context.commit(PaymentMutations.SET_MEMBER_STATEMENT_DEPOSIT, res);
		} else if (payload.type == "Withdrawal") {
			context.commit(PaymentMutations.SET_MEMBER_STATEMENT_WITHDRAWAL, res);
		}
	},
	async fetchMemberTransferStatement(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const toIncludeToday = new Date(new Date(payload.to).getTime() + 24 * 60 * 60 * 1000);
		const res = await paymentService.getTransferStatement(loginId, payload.from, toIncludeToday);
		context.commit(PaymentMutations.SET_MEMBER_STATEMENT_TRANSFER, res);
	},
	async addAutoDeposit(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
		const response = paymentService.addDeposit(loginId, payload.amount, payload.bank, ipAddress, payload.bonusId);
		return response;
	},
	async addManualDeposit(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
		const eventId = payload.eventId ? payload.eventId : 0;
		const eventRewardId = payload.eventRewardId ? payload.eventRewardId : 0;
		const bonusId = payload.bonusId ? payload.bonusId : 0;
		return await paymentService.addManualDeposit(
			loginId,
			payload.amount,
			payload.bank,
			payload.channel,
			payload.receipt,
			ipAddress,
			eventId,
			eventRewardId,
			bonusId
		);
	},
	async addWithdrawal(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const memberBank = context.rootGetters["member/getMemberBank"];
		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
		await paymentService.addWithdrawal(
			loginId,
			payload.amount,
			memberBank.bankCode,
			memberBank.accountNumber,
			memberBank.accountHolder,
			ipAddress
		);
	},
	async fetchMemberAdjustment(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		// const depositAdjustment = await paymentService.getStatement(loginId, "DepositAdj", payload.from, payload.to);
		// const withdrawalAdjustment = await paymentService.getStatement(loginId, "WithdrawalAdj", payload.from, payload.to);
		// const rebateAdjustment = await paymentService.getStatement(loginId, "RebateAdj", payload.from, payload.to);
		// const miniGamesAdjustment = await paymentService.getStatement(loginId, "MiniGamesAdj", payload.from, payload.to);
		// const adjustmentList = depositAdjustment.concat(withdrawalAdjustment, rebateAdjustment, miniGamesAdjustment);
		const adjustment = await paymentService.getStatement(
			loginId,
			"Adjustment",
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true),
			payload.maxAmount
		);
		context.commit(PaymentMutations.SET_MEMBER_ADJUSTMENT, adjustment);
	},
	async fetchMemberInterestStatement(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getStatement(
			loginId,
			"InterestTransfer",
			clientDateStringToServerISOString(payload.from),
			clientDateStringToServerISOString(payload.to, true)
		);
		context.commit(PaymentMutations.SET_MEMBER_STATEMENT_INTEREST, res);
	},
	async fetchBonusReferral(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getBonusReferral(loginId);
		context.commit(PaymentMutations.SET_MEMBER_BONUS_REFERRAL, res);
	},
	async transferBalance(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
		const transferResponse: TransferErrorResponse = {} as TransferErrorResponse;
		const addTransferRes = await paymentService.addTransfer(
			loginId,
			payload.vendorFrom,
			payload.vendorTo,
			payload.amount,
			payload.bonusID,
			ipAddress
		);
		if (addTransferRes.result) {
			const completeTransferRes = await paymentService.completeTransfer(
				addTransferRes.transferResponseDto.TicketId,
				payload.bonusID
			);
			if (completeTransferRes.succeeded) {
				transferResponse.succeeded = true;
				transferResponse.errorMsg = "";
			} else {
				transferResponse.succeeded = false;
				transferResponse.errorMsg = completeTransferRes.errors[0];
			}
		} else {
			transferResponse.succeeded = false;
			transferResponse.errorMsg = addTransferRes.errorMsg;
		}

		return transferResponse;
	},
	async fetchMemberBonusBirthday(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getBonusBirthday(loginId);
		context.commit(PaymentMutations.SET_MEMBER_BONUS_BIRTHDAY, res);
	},
	async transferAllAmount(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.transferAllAmount(loginId, payload.vendorFrom, payload.vendorTo);
		return res;
	},
	async transferBalToMain(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.transferAllBalToMain(loginId);
		return res;
	},

	async setTicketToRejected(context, payload) {
		const res = await paymentService.setTicketToRejected(payload.ticketId);
		return res;
	},

	async CheckBonusLock(context, payload) {
		const vendorFrom =
			payload.vendorFrom == "Main"
				? null
				: context.rootGetters["vendors/getActiveMemberVendor"](payload.vendorFrom)[0];
		const vendorTo =
			payload.vendorTo == "Main"
				? null
				: context.rootGetters["vendors/getActiveMemberVendor"](payload.vendorTo)[0];
		const allowVendorTransferSetting = await context.dispatch(
			"globalSetting/fetchGlobalSetting",
			{ remark: "AllowVendorTransfer" },
			{ root: true }
		);

		// if(allowVendorTransferSetting[0] == null)
		// 	return "";
		if (vendorFrom != null) {
			const vendorFromCategory = vendorFrom.categories.filter((category) => category.main == 1)[0].name;
			const turnoverBalance =
				allowVendorTransferSetting[0].content == "1"
					? context.rootGetters["memberAccount/getMemberTurnover"].turnoverBalances.filter(
							(turnover) => turnover.category == vendorFromCategory && turnover.isLocked
					  )[0]
					: context.rootGetters["memberAccount/getMemberTurnover"].turnoverBalances.filter(
							(turnover) => turnover.vendorId == vendorFrom.id && turnover.isLocked
					  )[0];

			const winoverBalance =
				allowVendorTransferSetting[0].content == "1"
					? context.rootGetters["memberAccount/getMemberWinover"].winoverBalances.filter(
							(winover) => winover.category == vendorFromCategory && winover.isLocked
					  )[0]
					: context.rootGetters["memberAccount/getMemberWinover"].winoverBalances.filter(
							(winover) => winover.vendorId == vendorFrom.id && winover.isLocked
					  )[0];

			if (winoverBalance !== undefined) {
				return "winover exist";
			}

			if (turnoverBalance !== undefined) {
				if (vendorTo == null) return "turnover exist";
			} else if (vendorTo != null) {
				const winoverBalance = context.rootGetters["memberAccount/getMemberWinover"].winoverBalances.filter(
					(winover) => winover.vendorId == vendorTo.id && winover.isLocked
				)[0];
				if (winoverBalance !== undefined) return "winover exist";
				const vendorToCategory = vendorFrom.categories.filter((category) => category.main == 1)[0].name;
				if (!(vendorFromCategory == vendorToCategory && allowVendorTransferSetting[0].content == "1")) {
					return "turnover exist";
				}
			}
		}

		if (vendorFrom == null) {
			const winoverBalance = context.rootGetters["memberAccount/getMemberWinover"].winoverBalances.filter(
				(winover) => winover.vendorId == vendorTo.id && winover.isLocked
			)[0];
			if (winoverBalance !== undefined) {
				return "winover exist";
			}
		}
	},
};
